import React from "react";

import SectionTitle from "../../shared/components/sectionTitle";
import SaveTreeCard from "./components/SaveTreeCard";
import SaveTreeInputsCard from "./components/SaveTreeInputsCard";

import "./style.css";

const SaveTrees = () => {
  return (
    <div className="save_tree_wrapper">
      <div className="content_wrapper save_tree_content">
        <SectionTitle color={"white"} title="DARAXTLARNI SAQLAB QOLING!" />
        <div className="save_tree_card_wrapper">
          <SaveTreeCard />
          <SaveTreeInputsCard />
        </div>
      </div>
    </div>
  );
};

export default SaveTrees;
