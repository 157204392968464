import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { Popover } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./style.css";

const ReceptionCard = ({
  image,
  fullName,
  job,
  scheduleDay,
  schedule,
  phone,
  email,
  telegramBot,
}) => {
  const { t } = useTranslation();

  const workingDays = ["Du", "Se", "Cho", "Pa", "Ju", "Sha"];

  return (
    <div data-aos="fade-up" className="reception_card_wrapper">
      <div className="employee_image_container">
        <LazyLoadImage
          src={image}
          alt="employee_image"
          className="employee_image"
          effect="blur"
        />
      </div>
      <div className="employee_main_information">
        <p className="employee_name">{fullName}</p>
        <p className="employee_job">{job}</p>
        <div className="employee_schedule">
          <p className="schedule_label">{t("Qabul kunlari")}</p>
          <div className="schedule_days">
            {workingDays.map((day) => (
              <span
                key={day}
                className={`schedule_day ${
                  scheduleDay?.includes(day) ? "schedule_day_active" : ""
                }`}
              >
                {t(day)}
              </span>
            ))}
          </div>
        </div>
        <p className="employee_working_hours">
          {t("Qabul vaqti")} <span>{schedule}</span>
        </p>
      </div>
      <div className="employee_icon_information">
        <div className="employee_icon_wrapper">
          <Popover
            placement="bottom"
            title={t("Telegram bot")}
            content={
              <a rel="noreferrer" href={telegramBot} target="_blank">
                {telegramBot}
              </a>
            }
          >
            <Icon color="#004D3F" width={"24px"} icon={"mdi:telegram"} />
          </Popover>
          <Popover
            placement="bottom"
            title={t("Telefon raqam")}
            content={
              <a rel="noreferrer" href={`tel:${phone}`} target="_blank">
                {phone}
              </a>
            }
          >
            <Icon color="#004D3F" width={"24px"} icon={"ic:baseline-phone"} />
          </Popover>
          <Popover
            placement="bottom"
            title={t("Email")}
            content={
              <a rel="noreferrer" href={`mailto: ${email}`} target="_blank">
                {email}
              </a>
            }
          >
            <Icon color="#004D3F" width={"24px"} icon={"mdi:envelope"} />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default ReceptionCard;
