import image1 from "./../assets/1.jpg";
import image2 from "./../assets/2.jpg";
import image3 from "./../assets/3.jpg";
import image4 from "./../assets/4.jpg";

export const mostCommentedNewsData = [
  {
    id: 7262543,
    postedDate: "22.07.2024",
    title_uz:
      "Botanika bog‘i Toshkent shahar hokimligi balansiga o‘tkazilmaydi",
    title_ru:
      "Ботанический сад не будет передан на баланс администрации Ташкента",
    title_en:
      "The Botanical Garden will not be transferred to the balance of the Tashkent city administration",
    description_uz:
      "Hokimlikning qayd etishicha, Botanika bog‘i istirohat bog‘i emas, u nafaqat ko‘p navli o‘simliklarni, balki shu soha mutaxassislarini ham yetishtirishga yordam beradigan ilm-fan dargohidir.",
    description_ru:
      "Как отмечают в администрации, Ботанический сад не является парком отдыха, это научное учреждение, которое помогает выращивать не только множество видов растений, но и специалистов в этой области.",
    description_en:
      "According to the administration, the Botanical Garden is not a recreational park; it is a scientific institution that helps cultivate not only a variety of plant species but also specialists in this field.",
    views: 315,
    image: image1,
  },
  {
    id: 1231231,
    postedDate: "21.07.2024",
    title_uz:
      "Toshkentning eng qimmat hududidan 25 sotix yerni o‘zlashtirib qurilish qilgan firma 10 mln so‘mlik jarimaga tortildi",
    title_ru:
      "Фирма, незаконно построившаяся на 25 сотках самой дорогой земли в Ташкенте, оштрафована на 10 млн сумов",
    title_en:
      "The company, which illegally built on 25 acres of the most expensive land in Tashkent, was fined 10 million sums",
    description_uz: `Toshkentda “Bobur” (Do‘stlik) bog‘idagi 25 sotix yerni o‘zboshimchalik bilan egallab olib, u yerda qurilish qilayotgan firmaga BHMning 30 baravari miqdorida jarima tayinlanibdi. Bu taxminan 10 mln so‘m bo‘ladi.

Xalqqa tegishli 25 sotix yerni ochiqchasiga o‘g‘rilab qurilish qilganlarga 10 mln so‘mlik jarima. Yer bog‘ hududiga qaytarilgani haqida maʼlumot berilmagan.`,
    description_ru: `В Ташкенте фирма, самовольно захватившая 25 соток земли в парке «Бабур» (Дустлик) и начавшая там строительство, оштрафована на 30 минимальных заработных плат (примерно 10 миллионов сумов).

Фирма незаконно построившая на 25 сотках земли, принадлежащей народу, оштрафована на 10 миллионов сумов. Информации о возвращении земли в парк нет.`,
    description_en: `In Tashkent, a company that illegally seized 25 acres of land in the "Babur" (Dostlik) park and started construction there was fined 30 times the minimum wage (approximately 10 million sums).

The company that illegally built on 25 acres of land belonging to the people was fined 10 million sums. There is no information on the return of the land to the park.`,
    views: 213,
    image: image2,
  },
  {
    id: 421341,
    postedDate: "19.07.2024",
    title_uz: "Ayiq otib o‘ldirilgani yuzasidan Ekopartiya munosabat bildirdi",
    title_ru: "Экопартия прокомментировала отстрел медведя",
    title_en: "Ecoparty commented on the shooting of a bear",
    description_uz: `15 may kuni Surxondaryo viloyati Sariosiyo tumanidagi Jannatmakon mahallasida tog‘dan tushib kelgan ayiq otib o‘ldirildi. Gap shundaki, ayiq ko‘cha kezib, tajovuzkor harakatlar qilgan. Ayiqni zararsizlantirish jarayonida u ichki ishlar xodimlaridan biriga tashlanib, jarohat yetkazgan. So‘ngra 74-maktab hududiga kirgan. Yirtqich maktab o‘quvchilari hayotiga xavf tug‘dirgani sababli tezkor chora ko‘rish talab qilingan va u otib o‘ldirilgan. Ushbu holat esa ijtimoiy tarmoqlarda muhokamalarga sabab bo‘lmoqda.`,
    description_ru: `15 мая в махалле Джаннатмакон района Сариосиё Сурхандарьинской области был застрелен медведь, спустившийся с гор. Дело в том, что медведь бродил по улицам и вел себя агрессивно. Во время его обезвреживания он напал на одного из сотрудников внутренних дел и нанес ему травму. Затем медведь проник на территорию школы №74. Из-за угрозы жизни школьников было принято решение о его ликвидации. Этот случай вызвал обсуждения в социальных сетях.`,
    description_en: `On May 15, a bear that descended from the mountains was shot dead in the Jannatmakon mahalla of Sariosiyo district, Surkhandarya region. The bear was roaming the streets and acting aggressively. During the neutralization process, the bear attacked one of the internal affairs officers, causing injury. It then entered the premises of school No. 74. Due to the threat to the lives of the schoolchildren, swift action was required, and the bear was shot dead. This incident has sparked discussions on social media.`,
    views: 291,
    image: image3,
  },
  {
    id: 241243,
    postedDate: "15.07.2024",
    title_uz:
      "51 ming tup (dastlabki muhokamalarda 70 ming deyilgan) daraxt saqlanib qolindi",
    title_ru:
      "Сохранены 51 тысяча деревьев (первоначально обсуждалось 70 тысяч)",
    title_en:
      "51 thousand trees saved (initial discussions mentioned 70 thousand)",
    description_uz: `Keng muhokama bo‘lgan Surxondaryo va Buxoro yo‘llari qurilishi uchun kesilishi rejalashtirilgan daraxtlar saqlanib qolinadi.
    
Jamoatchilik faollari, masʼul bo‘lgan deputatlar hamda Ekopartiya vakillariga o‘z minnatdorchiligimni bildiraman.

Zero, avval aytganimdek hech qanday qurilish daraxtlarning kesilishi evaziga bo‘lmasligi lozim!

P.S. Ekologiya, atrof-muhitni muxofaza qilish va iqlim o‘zgarishi vazirligi `,
    description_ru: `Сохранены деревья, которые планировалось вырубить для строительства дорог в Сурхандарье и Бухаре, обсуждавшиеся ранее.

Благодарю общественных активистов, ответственных депутатов и представителей Экопартии.

Как я уже говорил, никакое строительство не должно происходить за счет вырубки деревьев!

P.S. Министерство экологии, охраны окружающей среды и изменения климата`,
    description_en: `The trees that were planned to be cut down for the construction of roads in Surkhandarya and Bukhara, which were previously discussed, will be preserved.
    
I thank the public activists, responsible deputies, and representatives of the Ecoparty.

As I mentioned earlier, no construction should occur at the expense of cutting down trees!

P.S. Ministry of Ecology, Environmental Protection and Climate Change`,
    views: 412,
    image: image4,
  },
];
