import blaknot from "./../assets/blaknot.png";
import futbolka from "./../assets/futbolka.png";
import futbolka1 from "./../assets/futbolka1.png";
import futbolka2 from "./../assets/futbolka2.png";
import futbolka3 from "./../assets/futbolka3.png";
import kepka from "./../assets/kepka.png";
import ruchka from "./../assets/ruchka.png";
import sumka from "./../assets/sumka.png";
import bayrpoq from "./../assets/bayroq.jpg";

export const eShopData = [
  {
    id: 1,
    title_uz: "Bloknot",
    title_ru: "Блокнот",
    title_en: "Notebook",
    price: 15000,
    image: blaknot,
  },
  {
    id: 2,
    title_uz: "Futbolka",
    title_ru: "Футболка",
    title_en: "T-shirt",
    price: 50000,
    image: futbolka,
    size: ["L", "XL", "XXL"],
    color: ["green", "white", "black"],
  },
  {
    id: 3,
    title_uz: "Futbolka",
    title_ru: "Футболка",
    title_en: "T-shirt",
    price: 50000,
    image: futbolka1,
    size: ["L", "XL", "XXL"],
    color: ["green", "white", "black"],
  },
  {
    id: 4,
    title_uz: "Futbolka",
    title_ru: "Футболка",
    title_en: "T-shirt",
    price: 50000,
    image: futbolka2,
    size: ["L", "XL", "XXL"],
    color: ["green", "white", "black"],
  },
  {
    id: 5,
    title_uz: "Futbolka",
    title_ru: "Футболка",
    title_en: "T-shirt",
    price: 50000,
    image: futbolka3,
    size: ["L", "XL", "XXL"],
    color: ["green", "white", "black"],
  },
  {
    id: 6,
    title_uz: "Kepka",
    title_ru: "Кепка",
    title_en: "Cap",
    price: 40000,
    image: kepka,
  },
  {
    id: 7,
    title_uz: "Ruchka",
    title_ru: "Ручка",
    title_en: "Pen",
    price: 20000,
    image: ruchka,
  },
  {
    id: 8,
    title_uz: "Sumka",
    title_ru: "Сумка",
    title_en: "Bag",
    price: 15000,
    image: sumka,
  },
  {
    id: 9,
    title_uz: "Bayroq",
    title_ru: "Флаг",
    title_en: "Flag",
    price: 100000,
    image: bayrpoq,
  },
];
