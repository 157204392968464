import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DropZone from "../../../shared/components/dropZone";

import { updateEco } from "../../../repository/eco-backend";

const SaveTreeInputsCard = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [video, setVideo] = useState();
  const [videoSrc, setVideoSrc] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      formData.append("text", `Address: ${address}`);

      if (image) {
        formData.append("image", image[0]);
        await updateEco("post", "send-message-tg/", formData);
        setImage(null);
      }

      if (video) {
        formData.append("video", video[0]);
        await updateEco("post", "send-message-tg/", formData);
        setVideo(null);
      }

      setAddress("");
      setImage(null);
      setImageSrc();
      setVideo(null);
      setVideoSrc();
      alert("Muvafaqiyatli jonatilindi");
    } catch (error) {
      console.error("Xatolik yuz berdi", error);
    }
  };

  return (
    <form
      data-aos="fade-left"
      className="save_tree_inputs_card"
      onSubmit={handleSubmit}
    >
      <p className="save_tree_inputs_card_text">{t("sendSaveTree")}</p>
      <input
        value={address}
        type="text"
        placeholder={t("enterAddress")}
        className="save_tree_input"
        onChange={(e) => setAddress(e.target.value)}
        required
      />
      <div className="save_tree_inputs_wrapper">
        <DropZone
          setFile={setImage}
          fileSrc={imageSrc}
          setFileSrc={setImageSrc}
          dropzoneTitle={t("enterImage")}
        />
        <DropZone
          setFile={setVideo}
          fileSrc={videoSrc}
          setFileSrc={setVideoSrc}
          dropzoneTitle={t("enterVideo")}
        />
      </div>
      <button type="submit" className="save_tree_inputs_card_button">
        {t("send")}
      </button>
    </form>
  );
};

export default SaveTreeInputsCard;
