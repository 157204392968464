import image1 from "./../../../shared/assets/galeryImage1.jpg";
import image2 from "./../../../shared/assets/galeryImage2.jpg";
import image3 from "./../../../shared/assets/galeryImage3.jpg";
import image4 from "./../../../shared/assets/galeryImage4.jpg";
import image5 from "./../../../shared/assets/galeryImage5.jpg";
import image6 from "./../../../shared/assets/galeryImage6.jpg";
import image7 from "./../../../shared/assets/galeryImage7.jpg";
import image8 from "./../../../shared/assets/galeryImage8.jpg";
import image9 from "./../../../shared/assets/galeryImage9.jpg";
import image10 from "./../../../shared/assets/galeryImage10.jpg";

export const galeryImageData = [
  { id: 1, image: image1 },
  { id: 2, image: image2 },
  { id: 3, image: image3 },
  { id: 4, image: image4 },
  { id: 5, image: image5 },
  { id: 6, image: image6 },
  { id: 7, image: image7 },
  { id: 8, image: image8 },
  { id: 9, image: image9 },
  { id: 10, image: image10 },
];
