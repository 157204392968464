import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SectionTitle from "../../shared/components/sectionTitle";

import { donationAmount } from "./utils/donationAmount";
import { donationImages } from "./utils/donationPaymentMethods";
import { updateEco } from "../../repository/eco-backend";
import { formatNumber } from "../../shared/utils/numberUtils";

import "./style.css";

import payme from "./../../shared/assets/donationImages/payme.svg";
import click from "./../../shared/assets/donationImages/click.svg";

const Donation = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(null);

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const [periodSelect, setPeriodSelect] = useState(false);

  const handleAmountClick = (amount) => {
    setSelectedAmount(amount);
    if (amount !== null) {
      setCustomAmount("");
    }
  };

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 2) return number;
    if (number.length < 6) return number.replace(/(\d{2})(\d{1})/, "$1 $2");
    if (number.length < 8)
      return number.replace(/(\d{2})(\d{3})(\d{1})/, "$1 $2 $3");
    return number.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
  };

  const handlePhoneNumberChange = (e) => {
    const formattedValue = phoneNumberAutoFormat(e.target.value);
    setPhoneNumber(formattedValue);
  };

  const onClickPay = async () => {
    const selectedPaymentType = document.querySelector(
      'input[name="paymentType"]:checked'
    );

    if (
      !selectedPaymentType ||
      !phoneNumber ||
      phoneNumber?.length != 12 ||
      (!selectedAmount && !customAmount)
    ) {
      setError("Barcha maydonlarni to'ldiring va to'lov usulini tanlang.");
      return;
    } else {
      setError(null);
    }

    const selectedPaymentId = selectedPaymentType.id;
    const amount = selectedAmount || customAmount;

    if (selectedPaymentId === "payMe" && phoneNumber.length >= 1) {
      const response = await updateEco(
        "post",
        "payment/donations/",
        {
          phone_number: `+998${phoneNumber.replace(/\s+/g, "")}`,
          amount: amount,
          donation_type: "donat",
        },
        "https://payment.ecouz.uz"
      );
      if (response) {
        window.open(response?.data?.payment_url);
      }
    } else if (selectedPaymentId === "click" && phoneNumber.length >= 1) {
      const response = await updateEco(
        "post",
        "payment/click-donation/",
        {
          phone_number: `+998${phoneNumber.replace(/\s+/g, "")}`,
          amount: amount,
          donation_type: "donat",
        },
        "https://payment.ecouz.uz"
      );
      if (response) {
        window.open(response?.data?.payment_url);
      }
    }

    const formData = new FormData();
    formData.append(
      "text",
      `${`+998${phoneNumber.replace(
        /\s+/g,
        ""
      )}`} shu telefon raqam egasi ${formatNumber(
        amount
      )}so'm xayriya qilmoqda.`
    );

    await updateEco("post", "send-message-tg/", formData);

    setSelectedAmount(null);
    setCustomAmount("");
    setPhoneNumber("");
    setError(null);
  };

  return (
    <div id="donation" className="donation_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper donation_container"
      >
        <div className="donation_page_top">
          <SectionTitle title="XAYRIYA" />
          <div data-aos="fade-down" className="donation_goal">
            {t("Siz qilgan xayriya atrof-muhit muhofazasi uchun yo'naltiradi")}
          </div>
        </div>
        <div className="donation_main_page_wrapper">
          <div className="donation_amount_line">
            {donationAmount.map(({ id, key, amount }) => (
              <div
                onClick={() => handleAmountClick(key)}
                className={`donation_box ${
                  selectedAmount === key ? "donation_box_active" : ""
                }`}
                key={`12121${id}`}
              >
                {t(amount)}
              </div>
            ))}
          </div>

          <div className="donation_input_wrapper">
            <div style={{ top: "15px" }} className="phone_number_hint">
              +998
            </div>
            <input
              name="phone_number"
              required
              className="donation_inputs_input"
              style={{ padding: "10px 20px 10px 70px" }}
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={11}
            />
            <div className="donation_block"></div>
            <input
              className="donation_inputs_input"
              placeholder={t("Miqdorni kiriting")}
              type="number"
              disabled={selectedAmount !== null}
              onChange={(e) => setCustomAmount(e.target.value)}
              value={selectedAmount || customAmount}
            />
            <div className="donation_block"></div>
            <div className="radio_input_wrapper">
              <label htmlFor="payMe">
                <input type="radio" required id="payMe" name="paymentType" />
                <img className="radio_input_logo" src={payme} alt="" />
              </label>
              <label htmlFor="click">
                <input type="radio" required id="click" name="paymentType" />
                <img className="radio_input_logo" src={click} alt="" />
              </label>
            </div>
            {error && <p className="error">{error}</p>}
            <div className="donation_period_selector_wrapper">
              <div
                onClick={() => setPeriodSelect(false)}
                style={{ borderRadius: "12px 0 0 12px" }}
                className={`donation_period_selector ${
                  !periodSelect && "donation_period_selector_active"
                }`}
              >
                {t("Bir martalik")}
              </div>
              <div
                className={`donation_period_selector ${
                  periodSelect && "donation_period_selector_active"
                }`}
                style={{ borderRadius: "0 12px 12px 0" }}
                onClick={() => setPeriodSelect(true)}
              >
                {t("Oylik")}
              </div>
            </div>
            <button onClick={onClickPay} className="contact_us_button">
              {t("Keyingisi")}
            </button>
          </div>
        </div>
        <div className="payment_method_wrapper">
          {donationImages.map((payment, index) => (
            <div className="payment_method" key={`2121${index}`}>
              <img
                className="payment_method_image"
                src={payment.image}
                alt="payment_method_image"
              />
              <img
                className="payment_method_image"
                src={payment.qrImage}
                alt="payment_method_image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Donation;
