import React from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import savedTree from "./../../../shared/assets/savedTree.png";

const SaveTreeCard = () => {
  const { t } = useTranslation();

  return (
    <div data-aos="fade-right" className="save_tree_card">
      <img src={savedTree} alt="" />
      <div className="save_tree_card_info">
        <div className="save_tree_card_date">
          <Icon width={"20px"} icon="mdi:calendar" />
          <p> 02.06.2024 </p>
        </div>
        <Icon width={"40px"} icon="hugeicons:location-09" />
        <p className="save_tree_card_location">{t("saveTreeAddress")}</p>
        <div className="save_tree_card_button">{t("BATAFSIL")}</div>
      </div>
    </div>
  );
};

export default SaveTreeCard;
