import React, { useEffect, useState } from "react";
import usePageFetcher from "../repository/usePageFetcher";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";

import "./style.css";
import Loader from "../shared/components/loader";

const Program = () => {
  const { isLoading, data } = usePageFetcher("about/");
  const { i18n } = useTranslation();

  const [program, setProgram] = useState(null);

  useEffect(() => {
    setProgram(data?.[0]);
  }, [data]);

  return (
    <div className="program_wrapper">
      <div className="content_wrapper program_container">
        <SectionTitle title="Dastur" />

        {isLoading ? (
          <Loader />
        ) : (
          <div
            data-aos="fade-up"
            dangerouslySetInnerHTML={{
              __html: program?.[`program_${i18n.language}`],
            }}
            className="program_text"
          ></div>
        )}
      </div>
    </div>
  );
};

export default Program;
