import React, { createContext, useContext, useState, useEffect } from "react";
import { useFetchElectionData } from "../routes/useElectionFetch";

const ElectionContext = createContext();

export const ElectionProvider = ({ children }) => {
  const [electionData, setElectionData] = useState([]);
  const fetchedElectionData = useFetchElectionData();

  useEffect(() => {
    setElectionData(fetchedElectionData);
  }, [fetchedElectionData]);

  return (
    <ElectionContext.Provider value={{ electionData }}>
      {children}
    </ElectionContext.Provider>
  );
};

export const useElectionData = () => useContext(ElectionContext);
