import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "@iconify/react";

import "./style.css";

const DropZone = ({ fileSrc, setFileSrc, setFile, dropzoneTitle }) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles);
    setIsDragging(false);
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isVideo = fileSrc && fileSrc.startsWith("data:video");
  const isImage = fileSrc && fileSrc.startsWith("data:image");

  return (
    <Dropzone
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={onDrop}
      disabled={fileSrc}
    >
      {({ getRootProps, getInputProps }) => (
        <div className="drop_zone_wrapper">
          <div
            className={`drop_zone ${isDragging ? "drop_zone_active" : ""}`}
            {...getRootProps()}
          >
            {fileSrc ? (
              <div className="dropped_file_wrapper">
                <div className="dropped_file_delete">
                  <Icon
                    onClick={() => setFileSrc(null)}
                    width={"20px"}
                    color={"#EE027E"}
                    icon="ic:outline-delete"
                  />
                </div>
                {isImage && (
                  <img className="dropped_file" src={fileSrc} alt="Dropped" />
                )}
                {isVideo && (
                  <video className="dropped_file" controls src={fileSrc} />
                )}
              </div>
            ) : (
              <div>
                <input {...getInputProps()} />
                <p>{dropzoneTitle}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DropZone;
