import React from "react";

import Pages from "../routes/Pages";
import Header from "./navbar";
import Footer from "./footer";

const Layout = () => {
  return (
    <div>
      <Header />
      <Pages />
      <Footer />
    </div>
  );
};

export default Layout;
