import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

import SectionTitle from "../shared/components/sectionTitle";

import { galeryImageData } from "../home/galery/utils/galeryImageData";

import "./style.css";

const PhotoGallery = () => {
  return (
    <div className="video_gallery_wrapper">
      <div className="content_wrapper video_gallery_container">
        <SectionTitle title={"FOTOGALEREYA"} />
        <div className="videos_wrapper">
          <PhotoProvider
            easing={(type) =>
              type === 2
                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
          >
            {galeryImageData.map((image) => (
              <PhotoView src={image.image}>
                <img
                  className="galery_card_video"
                  src={image.image}
                  alt={`image`}
                />
              </PhotoView>
            ))}
          </PhotoProvider>
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
