import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import NewsCard from "../../../home/news/components/NewsCard";
import Loader from "../../components/loader";

import { formatNumber } from "../../utils/numberUtils";
import usePageFetcher from "../../../repository/usePageFetcher";
import { epochToTimeConverter } from "../../utils/dateTimeUtils";
import { getNewsById } from "../../services/news";

import "./style.css";
import "react-photo-view/dist/react-photo-view.css";

const ContentDetails = () => {
  const { newsId } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { data: news } = usePageFetcher(`news/`);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchNews = async () => {
      setIsLoading(true);
      try {
        const result = await getNewsById(newsId);
        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, [newsId]);

  return (
    <div className="content_details_wrapper">
      {isLoading ? (
        <Loader />
      ) : (
        <div
          style={{ alignItems: "start" }}
          className="content_wrapper content_details_container"
        >
          <div className="main_content_details_wrapper">
            <div className="content_posted_info">
              <p className="news_posted_date">
                {epochToTimeConverter(data?.posted_at)}
              </p>
              |
              <p className="news_views">
                <Icon icon="mdi:eye" />
                {formatNumber(data?.see)}
              </p>
            </div>
            <p className="content_details_title">
              {data?.[`title_${i18n.language}`]}
            </p>
            <PhotoProvider
              easing={(type) =>
                type === 2
                  ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                  : "cubic-bezier(0.34, 1.56, 0.64, 1)"
              }
            >
              <PhotoView src={data?.img}>
                <img
                  className="content_details_image"
                  src={data?.img}
                  alt={`contentImages`}
                />
              </PhotoView>
            </PhotoProvider>
            <div
              className="content_details_description"
              dangerouslySetInnerHTML={{
                __html: data?.[`description_${i18n.language}`],
              }}
            ></div>
          </div>
          <div className="extra_info">
            <div className="subscribe_telegram_card">
              <Icon width="30px" icon={"iconoir:telegram"} />
              <p>{t("telegramSubscribe")}</p>
              <a
                href="https://t.me/ecopartyuzb"
                target="_blank"
                rel="noreferrer"
                className="subscribe_telegram_button"
              >
                {t("becomeMember")}
              </a>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="extra_info_news_card">
                {news
                  ?.filter((value) => value?.id != newsId)
                  .slice(1, 4)
                  .map((news, index) => {
                    return (
                      <NewsCard
                        key={index}
                        cardInfoClassName={"extra_news_card_info"}
                        image={news?.img}
                        description={news?.[`title_${i18n.language}`]}
                        views={news?.see}
                        postedDate={epochToTimeConverter(news?.posted_at)}
                        onClick={() => navigate(`/news/${news?.id}`)}
                        descriptionClassName={"extra_news_card_info_title"}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentDetails;
