import React from "react";

import "./style.css";

const ECOCard = ({ image, title, description, date, author, link }) => {
  return (
    <div className="eco_card_wrapper">
      <div className="eco_card">
        {image && <img src={image} alt="" className="eco_card_image" />}
        <div className="eco_card_content">
          <div className="eco_card_header">
            <span className="eco_card_date">{date}</span>
            <div className="eco_card_title">{title}</div>
          </div>
          <p className="eco_card_description">{description}</p>
          <div className="eco_card_footer">
            {author && <div className="article_author">- {author}</div>}
            {link && (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="more_news_navigator"
              >
                Batafsil
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECOCard;
