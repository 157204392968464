import React from "react";

import Topbar from "./Topbar";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <div className="main_navbar">
      <Topbar />
      <div className="header_divider"></div>
      <Navbar />
    </div>
  );
};

export default Header;
