import React, { useState } from "react";
import Modal from "react-modal";

import SectionTitle from "../shared/components/sectionTitle";

import "./style.css";

const VideoGallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="video_gallery_wrapper">
      <div className="content_wrapper video_gallery_container">
        <SectionTitle title={"VIDEOGALEREYA"} />
        <div className="videos_wrapper">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <>
              <div
                className="galery_card_video"
                style={
                  index % 2 === 0
                    ? { flexDirection: "row-reverse" }
                    : { flexDirection: "row" }
                }
              >
                <div className="galery_card_video_wrapper">
                  <iframe
                    width="100%"
                    height="250px"
                    src="https://www.youtube.com/embed/2azL7rH_ZXo?si=X4cJHqIG8QiQEsl2"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                className="modal"
                overlayClassName="overlay"
                appElement={document.getElementById("root")} // Set your app element here
              >
                <button onClick={closeModal} className="modal_close_button">
                  x
                </button>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/2azL7rH_ZXo?si=X4cJHqIG8QiQEsl2"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </Modal>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
