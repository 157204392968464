import partner1 from "../assets/imgs/1.png";
import partner2 from "../assets/imgs/2.png";
import partner3 from "../assets/imgs/3.png";
import partner4 from "../assets/imgs/4.png";
import partner5 from "../assets/imgs/5.png";
import partner6 from "../assets/imgs/6.png";
import partner7 from "../assets/imgs/7.png";
import partner8 from "../assets/imgs/8.png";
import partner9 from "../assets/imgs/9.png";
import partner10 from "../assets/imgs/10.png";
import partner11 from "../assets/imgs/11.png";
import partner12 from "../assets/imgs/12.png";
import partner13 from "../assets/imgs/13.png";

export const partnershipData = [
  {
    id: 1,
    img: partner1,
    path: "https://iibb.uz/",
  },
  {
    id: 2,
    img: partner2,
    path: "https://ssv.uz/",
  },
  {
    id: 3,
    img: partner3,
    path: "http://www.uzedu.uz/uz",
  },
  {
    id: 4,
    img: partner4,
    path: "https://urmon.gov.uz/",
  },
  {
    id: 5,
    img: partner5,
    path: "https://minenergy.uz/",
  },
  {
    id: 6,
    img: partner6,
    path: "https://uznature.uz/",
  },
  {
    id: 7,
    img: partner7,
    path: "https://uz.yellowpages.uz/korxona/ozbekiston-respublikasi-veterinariya-va-chorvachilikni-rivojlantirish-davlat-qomitasi",
  },
  {
    id: 8,
    img: partner8,
    path: "https://edu.uz/uz",
  },
  {
    id: 9,
    img: partner9,
    path: "https://suvchi.gov.uz/uz/raxbariyat",
  },
  {
    id: 10,
    img: partner10,
    path: "https://www.prokuratura.uz/",
  },
  {
    id: 11,
    img: partner11,
    path: "https://uz.yellowpages.uz/korxona/gidrometeorologiya-xizmati-markazi-ozgidromet",
  },
  {
    id: 12,
    img: partner12,
    path: "https://www.tsul.uz/",
  },
  {
    id: 12,
    img: partner13,
    path: "http://ngo.uz/ru",
  },
];
