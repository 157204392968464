export const articlesData = [
  {
    id: 1,
    title: "Ekologik barqarorlikni ta’minlashda mas’ullik va faollik zarur",
    description:
      "Dunyo miqyosida har yili 5-iyun — Butunjahon atrof-muhitni muhofaza qilish kuni sifatida keng nishonlanadi.",
    author: "Gapporov Xayrillo Lutpillayevich",
    link: "https://parliament.gov.uz/articles/2074",
    date: "05.06.2024",
  },
  {
    id: 2,
    title: "Inson qadri ulug‘, xotirasi abadiydir",
    description:
      "Inson uchun tinchlik va osoyishtalikdan azizroq ne’mat, baxt yo‘q. Tinchligini yo‘qotgan xonadon ham, mamlakat ham aslo xotirjam bo‘la olmaydi.",
    author: "Tirkasheva Mukaddas Baxromovna",
    link: "https://parliament.gov.uz/articles/2056",
    date: "14.05.2024",
  },
  {
    id: 3,
    title: "Ekologik madaniyat — tabiat musaffoligining muhim omili",
    description:
      "Sayyoramizning kelajagi yosh avlodning ekologik madaniyatiga bog‘liq ekanligi barchamizga yaxshi ayon.",
    author: "Xushvaqtov Islam Nortashovich",
    link: "https://parliament.gov.uz/articles/2041",
    date: "15.04.2024",
  },
  {
    id: 4,
    title: "Farzandingizni internetda qanday himoya qilishni bilasizmi?",
    description:
      "Ma’lumotlarga ko‘ra, dunyo bo‘yicha 7 yoshdan 12 yoshgacha bo‘lgan bolalarning 90 foizdan ortig‘i internetga ulangan smartfon va planshetlardan foydalanadi.",
    author: "Abduxalimov Shuxrat Abduxalimovich",
    link: "https://parliament.gov.uz/articles/2029",
    date: "25.03.2024",
  },
  {
    id: 5,
    title: "Ekologik ta’lim — tabiat musaffoligining muhim omili",
    description:
      "Ekologik ta’lim-tarbiya tabiat va jamiyat o‘rtasidagi uzviylikni ta’minlash hamda tabiiy barqarorlikni saqlashda muhim ahamiyatga ega",
    author: "Jumaniyozov Kamol Komilovich",
    link: "https://parliament.gov.uz/articles/1769",
    date: "07.10.2023",
  },
  {
    id: 6,
    title:
      "Gidroelektrstansiyalar — energiyani barqaror ta’minlashda eng samarali vosita",
    description:
      "Har qanday mamlakatda iqtisodiy taraqqiyotning asosiy omillaridan biri bu energiya resurslaridir.",
    author: "Aminov Nosirjon Xasanbayevich",
    link: "https://parliament.gov.uz/articles/1833",
    date: "03.11.2023",
  },
  {
    id: 6,
    title: "Ona tabiatni asrash — sog‘lom turmush kafolati",
    description:
      "Muqaddas tuproq, musaffo havo, zilol suv, o‘z nurini saxiylik ila sochayotgan quyosh – barcha-barchasi tabiatning betakror mo‘jizasi.",
    author: "Yusupov Navruzbek Mukimjonovich",
    link: "https://parliament.gov.uz/articles/2075",
    date: "05.06.2024",
  },
  {
    id: 7,
    title: "Respublika doirasida yashil bog‘lar barpo etiladi",
    description:
      "Ma’lumki, “O‘zbekiston — 2030” strategiyasini “Yoshlar va biznesni qo‘llab-quvvatlash yili”da amalga oshirishga oid davlat dasturi loyihasi keng jamoatchilik muhokamasiga qo‘yildi.",
    author: "Yusupov Navruzbek Mukimjonovich",
    link: "https://parliament.gov.uz/articles/1976",
    date: "03.02.2024",
  },
];
