import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import SectionTitle from "../shared/components/sectionTitle";

import { eShopData } from "./utils/eShopData";
import { formatNumber } from "../shared/utils/numberUtils";

import "./style.css";

const EShop = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const addToCart = (product) => {
    alert(`${product[`title_${i18n.language}`]} ${t("savatchaga qo'shildi")}`);
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const calculateTotalPrice = () => {
    return cart.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

  const onClickCheckout = () => {
    navigate(`/checkout?price=${calculateTotalPrice()}`);
  };

  return (
    <div className="e_shop_wrapper">
      <div className="content_wrapper e_shop_container">
        <SectionTitle title="E-shop" />
        <div className="e_shop_cart" onClick={toggleCart}>
          <Icon icon="mdi:cart" />
          <div className="badge">{cart.length}</div>
        </div>

        <div className="e_shop_card_wrapper">
          {eShopData.map((item) => (
            <div data-aos="fade-up" className="e_shop_card" key={item.id}>
              <LazyLoadImage
                effect="blur"
                src={item.image}
                alt={item[`title_${i18n.language}`]}
                className="e_shop_card_image"
              />
              <div className="e_shop_card_sizes">
                {item.size &&
                  item.size.length > 0 &&
                  item.size.map((size) => (
                    <label htmlFor={size} key={size}>
                      <input
                        type="radio"
                        required
                        id={size}
                        name={`size_${item.id}`}
                      />
                      <span>{size}</span>
                    </label>
                  ))}
              </div>
              <div className="e_shop_card_colors">
                {item.color &&
                  item.color.length > 0 &&
                  item.color.map((color) => (
                    <label htmlFor={color} key={color}>
                      <input
                        type="radio"
                        required
                        id={color}
                        name={`color_${item.id}`}
                      />
                      <span
                        className="t_shirt_color"
                        style={{ background: color }}
                      />
                    </label>
                  ))}
              </div>
              <div className="e_shop_card_content">
                <div className="product_title">
                  {item[`title_${i18n.language}`]}
                </div>
                <p>{formatNumber(item.price)} so'm</p>
              </div>
              <button
                className="e_shop_card_button"
                onClick={() => addToCart(item)}
              >
                {t("Savatga qo'shish")}
              </button>
            </div>
          ))}
        </div>
      </div>
      {isCartOpen && (
        <div className="cart_modal">
          <div className="cart_modal_content">
            <h2>{t("Savatcha")}</h2>
            {cart.length > 0 ? (
              <ul className="text_cart">
                {cart.map((item, index) => (
                  <li key={index}>
                    {item[`title_${i18n.language}`]} - {item.quantity} x{" "}
                    {formatNumber(item.price)} so'm
                  </li>
                ))}
                <p className="text_cart">
                  {t("Umumiy narx")}: {formatNumber(calculateTotalPrice())} so'm
                </p>
              </ul>
            ) : (
              <p className="text_cart">{t("Sizning savatchangiz bo'sh")}</p>
            )}
            <div className="card_modal_buttons">
              <button onClick={toggleCart} className="close_button">
                {t("Yopish")}
              </button>
              {cart.length > 0 && (
                <button
                  onClick={onClickCheckout}
                  className="close_button go_to_checkout"
                >
                  {t("Buyurtmani rasmiylashtirish")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EShop;
