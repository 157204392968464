import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "../shared/components/loader";
import SectionTitle from "../shared/components/sectionTitle";
import NewsCard from "../home/news/components/NewsCard";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const Deputes = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, data } = usePageFetcher("document/");

  return (
    <div className="advices_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper advices_container"
      >
        <SectionTitle title={"Deputatlikka nomzodlar"} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="news_box">
            {data.map((tip, index) => {
              return (
                <NewsCard
                  key={123 + index}
                  image={tip.image}
                  description={tip[`title_${i18n.language}`]}
                  views={tip.views || 1}
                  postedDate={tip.postedDate || "21.09.2024"}
                  descriptionClassName={"all_news_description"}
                  imageClassName={"all_news_image"}
                  onClick={() => navigate(`/document/${tip?.id}`)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Deputes;
