import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../shared/components/loader";

import { getCentralCouncilById } from "../shared/services/centralCouncil";

import "./style.css";

const LeadershipDetails = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();

  const [state, setState] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCouncil = async () => {
      setIsLoading(true);
      try {
        const result = await getCentralCouncilById(id);
        setState(result);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchCouncil();
  }, [id]);

  return (
    <div className="leadership_details">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content_wrapper">
          <div className="leadership_details_wrapper">
            <img
              src={state?.img}
              alt={state?.title_uz}
              className="leadership_details_image"
            />
            <h1 className="leadership_details_name">
              {state?.[`title_${i18n.language}`]}
            </h1>
            <h2 className="leadership_details_job">
              {state?.[`job_${i18n.language}`]}
            </h2>
            <a
              href={`tel:${state?.phone_number}`}
              className="leadership_details_phone"
            >
              {state?.phone_number}
            </a>
            <h2 style={{ marginTop: "-20px" }}>
              <a
                href={`tel:${state?.email}`}
                className="leadership_details_phone"
              >
                {state?.email}
              </a>
            </h2>
            <div
              className="leadership_details_info"
              dangerouslySetInnerHTML={{
                __html: state?.[`biography_${i18n.language}`],
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadershipDetails;
