import React, { useState, useEffect } from "react";

import foto from "../../assets/logo.svg";

import "./style.css";

const Loader = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 2000);

    const removeTimer = setTimeout(() => {
      setIsVisible(false);
    }, 3500);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(removeTimer);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`loader_main ${fadeOut ? "fade-out" : ""}`}>
      <span className="home_page_loader"></span>
      <img src={foto} alt="" />
    </div>
  );
};

export default Loader;
