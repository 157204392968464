import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./style.css";

const BreadcrumbComponent = ({
  parentPagePath,
  parentPageTitle,
  currentPageTitle,
}) => {
  const { t } = useTranslation();
  return (
    <div className="breadcrumb_wrapper">
      <Link to={parentPagePath} replace className="parent_path">
        {t(parentPageTitle)}
      </Link>
      /<p className="ml-2">{t(currentPageTitle)}</p>
    </div>
  );
};

export default BreadcrumbComponent;
