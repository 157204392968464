import image1 from "./../../../shared/assets/helpUsImage1.png";
import image2 from "./../../../shared/assets/helpUsImage2.png";
import image3 from "./../../../shared/assets/helpUsImage3.png";

export const helpUsData = [
  {
    id: 1,
    title: "HOMIYLIK QILISH ORQALI...",
    description:
      "Mamlakatimizda olib borilayotgan bunyodkorlik ishlari zamirida inson qadrini ulugʻlashdek ezgu maqsadlar mujassam...",
    image: image1,
    href: "#donation",
  },
  {
    id: 3,
    title: "PARTIYAGA A`ZO BO’LISH ORQALI..",
    description:
      "Mamlakatimizda olib borilayotgan bunyodkorlik ishlari zamirida inson qadrini ulugʻlashdek ezgu maqsadlar mujassam...",
    image: image3,
    href: "https://azolik.ecouz.uz/",
  },
  {
    id: 2,
    title: "VOLONTER BO’LISH ORQALI...",
    description:
      "Mamlakatimizda olib borilayotgan bunyodkorlik ishlari zamirida inson qadrini ulugʻlashdek ezgu maqsadlar mujassam...",
    image: image2,
    href: "https://t.me/ecopartyuzb_bot",
  },
];
