import React from "react";
import { Navigation, Pagination, Keyboard, Autoplay } from "swiper/modules";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";

import { galeryImageData } from "../../utils/galeryImageData";

import "../../style.css";
import "react-photo-view/dist/react-photo-view.css";

const GaleryImage = () => {
  const teamSlider = {
    spaceBetween: 20,
    navigation: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <div className="galery_image_wrapper">
      <PhotoProvider
        easing={(type) =>
          type === 2
            ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
            : "cubic-bezier(0.34, 1.56, 0.64, 1)"
        }
      >
        <Swiper
          data-aos="fade-up"
          {...teamSlider}
          loop={true}
          slidesPerView={3}
          spaceBetween={30}
          keyboard={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation, Keyboard, Autoplay]}
          className="mySwiper"
        >
          {galeryImageData.map((image) => (
            <SwiperSlide className="partnership_box" key={image.id}>
              <PhotoView src={image.image}>
                <img
                  className="galery_image_image"
                  src={image.image}
                  alt={`image`}
                />
              </PhotoView>
            </SwiperSlide>
          ))}
        </Swiper>
      </PhotoProvider>
    </div>
  );
};

export default GaleryImage;
