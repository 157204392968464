import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const GaleryCard = ({ galeryTitle, galeryVideo, index }) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <div
        className="galery_card"
        style={
          index % 2 === 0
            ? { flexDirection: "row-reverse" }
            : { flexDirection: "row" }
        }
      >
        <div className="galery_card_video_wrapper">
          <div className="galery_card_image_container">
            <img
              className="galery_card_video"
              src={galeryVideo}
              alt="galeryImage"
              onClick={openModal}
            />
            <div className="galery_card_gradient_overlay"></div>
            <div className="galery_card_video_player" onClick={openModal}>
              <Icon icon="ph:play-fill" />
            </div>
          </div>
        </div>
        <div className="galery_card_description">{galeryTitle}</div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="overlay"
        appElement={document.getElementById("root")}
      >
        <button onClick={closeModal} className="modal_close_button">
          {t("Yopish")}
        </button>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/2azL7rH_ZXo?si=X4cJHqIG8QiQEsl2"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
};

export default GaleryCard;
