import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import SectionTitle from "../shared/components/sectionTitle";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const YouthParliament = () => {
  const { i18n } = useTranslation();
  const { isLoading, data } = usePageFetcher("yoshlar-parlamenti/");

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (data) {
      const newData = [...data].sort((a, b) => a.id - b.id);

      setSortedData(newData);
    }
  }, [data]);

  return (
    <div className="youth_parliament">
      <div className="content_wrapper youth_parliament_container">
        <SectionTitle title={"Yoshlar parlamenti"} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="volunteers_card_wrapper">
            {sortedData?.map((data) => {
              return (
                <div
                  data-aos="fade-up"
                  className="volunteers_card"
                  key={`${data?.id}383838383`}
                >
                  <LazyLoadImage
                    className="volunteers_card_image"
                    effect="blur"
                    src={data.img}
                    alt=""
                  />
                  <p className="volunteers_card_title">
                    {data[`full_name_${i18n.language}`]}
                  </p>
                  <p className="volunteers_card_description">
                    {data[`job_${i18n.language}`]}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default YouthParliament;
