import React from "react";
import {
  YMaps,
  Map,
  ObjectManager,
  ZoomControl,
  GeolocationControl,
  TrafficControl,
  FullscreenControl,
  TypeSelector,
  RulerControl,
  RouteButton,
} from "@pbe/react-yandex-maps";
import { useTranslation } from "react-i18next";

const MapComponent = ({ lat, long, title }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
      }}
      className="territorial_division_frame"
      data-aos="fade-up"
    >
      <YMaps>
        <div
          className="yandex_map_style"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "400px",
          }}
        >
          <Map
            defaultState={{
              center: [lat, long],
              zoom: 10,
            }}
            width={"100%"}
            height={"100%"}
          >
            <ZoomControl options={{ float: "right" }} />
            <GeolocationControl options={{ float: "left" }} />
            <TrafficControl options={{ float: "right" }} />
            <FullscreenControl />
            <TypeSelector options={{ float: "right" }} />
            <RulerControl options={{ float: "right" }} />
            <RouteButton options={{ float: "right" }} />

            <ObjectManager
              options={{
                clusterize: true,
                gridSize: 32,
              }}
              objects={{
                openBalloonOnClick: true,
                preset: "islands#greenDotIcon",
              }}
              clusters={{
                preset: "islands#redClusterIcons",
              }}
              filter={(object) => object.id % 2 === 0}
              modules={[
                "objectManager.addon.objectsBalloon",
                "objectManager.addon.objectsHint",
              ]}
              features={{
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    id: 0,
                    geometry: {
                      type: "Point",
                      coordinates: [lat, long],
                    },
                    properties: {
                      balloonContent: title,
                      hintContent: t("Batafsil"),
                    },
                  },
                ],
              }}
            />
          </Map>
        </div>
      </YMaps>
    </div>
  );
};

export default MapComponent;
