import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SectionTitle from "../../shared/components/sectionTitle";
import BreadcrumbComponent from "../../shared/components/breadcrumb/BreadcrumbComponent";
import MapComponent from "../../shared/components/yandexMap";

import { territorialDivisionData } from "../territorialDivisions/utils/territorialDivisionData";

import "./style.css";

const TerritorialDivision = () => {
  const { divisionId } = useParams();
  const { t, i18n } = useTranslation();

  const [territorialDivision, setTerritorialDivision] = useState();

  useEffect(() => {
    const divisionData = territorialDivisionData.find(
      (division) => division.id == divisionId
    );

    setTerritorialDivision(divisionData);
  }, []);

  return (
    <div className="territorial_division_details">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper territorial_division_container"
      >
        <BreadcrumbComponent
          parentPageTitle={"Asosiy"}
          parentPagePath={"/"}
          currentPageTitle={"Hududiy bo'limlar"}
        />
        <SectionTitle title={territorialDivision?.title} />
        <div className="territorial_division_card">
          <div className="deputy_card">
            <img
              data-aos="fade-up"
              src={territorialDivision?.deputyImage}
              alt=""
            />
            <div data-aos="fade-up" className="deputy_card_text">
              <p>
                {t("Rahbar")}:{" "}
                <span>
                  {territorialDivision?.[`deputyName_${i18n.language}`]}
                </span>
              </p>
              <p>
                {t("Lavozim")}:{" "}
                <span>{territorialDivision?.[`job_${i18n.language}`]}</span>
              </p>
              <p>
                {t("Manzil")}:{" "}
                <span>{territorialDivision?.[`address_${i18n.language}`]}</span>
              </p>
              {territorialDivision?.nearAddress_uz?.length >= 1 && (
                <p>
                  {t("Manzilga yaqin joylashuv")}:{" "}
                  <span>
                    {territorialDivision?.[`nearAddress_${i18n.language}`]}
                  </span>
                </p>
              )}
              <p>
                {t("Telefon raqam")}:{" "}
                <span>{territorialDivision?.phoneNumber}</span>
              </p>
              <a href={`mailto:${territorialDivision?.email}`}>
                {t("Email")}: <span>{territorialDivision?.email}</span>
              </a>
            </div>
          </div>
          <div className="territorial_division_image_card">
            <img
              data-aos="fade-up"
              className="territorial_division_image"
              src={territorialDivision?.image}
            />
            <MapComponent
              lat={41.2800072}
              long={69.2790118}
              title={territorialDivision?.[`address_${i18n.language}`]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerritorialDivision;
