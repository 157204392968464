import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";

import { updateEco } from "../repository/eco-backend";

import "./style.css";

import qrPayme from "./../shared/assets/donationImages/qrPayme.png";
import qrClick from "./../shared/assets/donationImages/qrClick.png";
import payme from "./../shared/assets/donationImages/payme.svg";
import click from "./../shared/assets/donationImages/click.svg";

const Donation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const extractPricesFromUrl = (urlFragment) => {
    const regex = /price=(\d+)/g;
    const matches = urlFragment.match(regex);

    if (!matches) {
      return [];
    }

    const prices = matches.map((match) =>
      parseInt(match.replace("price=", ""), 10)
    );

    return prices.join("") || "";
  };

  const price = extractPricesFromUrl(location.search);

  const [paymentPrice, setPaymentPrice] = useState(price ?? "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [clickError, setClickError] = useState(null);

  const onClickPay = async () => {
    const selectedPaymentType = document.querySelector(
      'input[name="paymentType"]:checked'
    );

    if (paymentPrice.length <= 3) {
      setClickError("Minimum summa 1000 so'm");
    } else {
      setClickError(null);
    }

    if (
      !paymentPrice ||
      !phoneNumber ||
      !selectedPaymentType ||
      phoneNumber?.length != 12
    ) {
      setError("Barcha maydonlarni to'ldiring va to'lov usulini tanlang.");
      return;
    }

    const selectedPaymentId = selectedPaymentType.id;
    if (
      selectedPaymentId === "payMe" &&
      paymentPrice.length >= 1 &&
      phoneNumber.length >= 1
    ) {
      const response = await updateEco(
        "post",
        "payment/donations/",
        {
          phone_number: `+998${phoneNumber?.replace(/\s+/g, "")}`,
          amount: paymentPrice,
          donation_type: "order",
        },
        "https://payment.ecouz.uz"
      );
      window.open(response?.data?.payment_url);
    } else if (
      selectedPaymentId === "click" &&
      paymentPrice.length >= 1 &&
      phoneNumber.length >= 1
    ) {
      const response = await updateEco(
        "post",
        "payment/click-donation/",
        {
          phone_number: `+998${phoneNumber?.replace(/\s+/g, "")}`,
          amount: paymentPrice,
          donation_type: "order",
        },
        "https://payment.ecouz.uz"
      );
      if (response) {
        window.open(response?.data?.payment_url);
      }
    }

    const formData = new FormData();

    formData.append(
      "text",
      `+998${phoneNumber.replace(
        /\s+/g,
        ""
      )} shu telefon raqam egasi buyurtma qilmoqda.`
    );

    await updateEco("post", "send-message-tg/", formData);

    setPaymentPrice("");
    setPhoneNumber("");
    setError(null);
    navigate("/");
  };

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 2) return number;
    if (number.length < 6) return number.replace(/(\d{2})(\d{1})/, "$1 $2");
    if (number.length < 8)
      return number.replace(/(\d{2})(\d{3})(\d{1})/, "$1 $2 $3");
    return number.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
  };

  const handlePhoneNumberChange = (e) => {
    const formattedValue = phoneNumberAutoFormat(e.target.value);
    setPhoneNumber(formattedValue);
  };

  return (
    <div className="donation_page_wrapper">
      <div className="content_wrapper donation_container">
        <SectionTitle title={"Buyurtmani rasmiylashtirish"} />
        <div className="donation_card_wrapper">
          <div className="donation_inputs">
            <p className="donation_inputs_label">{t("Tolov miqdori")}</p>
            <input
              required
              disabled={price.length >= 1 && true}
              className="donation_inputs_input"
              type="text"
              value={paymentPrice}
              onChange={(e) => setPaymentPrice(e.target.value)}
            />
            {clickError && <p className="error">{clickError}</p>}
            <div className="donation_block"></div>
            <p className="donation_inputs_label">{t("Telefon raqam")}</p>
            <div className="phone_number_hint">+998</div>
            <input
              name="phone_number"
              required
              className="donation_inputs_input"
              type="text"
              style={{ padding: "10px 20px 10px 70px" }}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={11}
            />
            <div className="donation_block"></div>
            <form>
              <div className="radio_input_wrapper">
                <label htmlFor="payMe">
                  <input type="radio" required id="payMe" name="paymentType" />{" "}
                  <img className="radio_input_logo" src={payme} alt="" />
                </label>
                <label htmlFor="click">
                  <input type="radio" required id="click" name="paymentType" />
                  <img className="radio_input_logo" src={click} alt="" />
                </label>
              </div>
            </form>
            <p className="error">{error}</p>
            <button
              onClick={onClickPay}
              className="contact_us_button"
              type="submit"
            >
              {t("Pul to'lash")}
            </button>
          </div>
          <div className="donation_qr_code">
            <img className="donation_qr_code_image" src={qrClick} alt="" />
            <img className="donation_payment_method_image" src={click} alt="" />
          </div>
          <div className="donation_qr_code">
            <img className="donation_qr_code_image" src={qrPayme} alt="" />
            <img className="donation_payment_method_image" src={payme} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
