import React, { useEffect, useState } from "react";
import { Turn } from "hamburger-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import { Icon } from "@iconify/react";

import { navbarRoutes } from "../../routes/configs/navbarRoutes";
import { mainRoutes } from "../../routes/configs/mainRoutes";
import { useElectionData } from "../../context/ElectionContext";

import "./style.css";

const HamburgeMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { electionData } = useElectionData();
  const location = useLocation();

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const onClickTitle = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const onClick = ({ key }) => {
    setIsOpen(false);
    return navigate(key);
  };

  useEffect(() => {
    if (location.hash === "#donation") {
      setTimeout(() => {
        const targetElement = document.getElementById("donation");
        window.scrollTo({
          top: targetElement?.offsetTop - 300,
        });
        setIsOpen(false);
      }, 1200);
    }
  }, [location]);

  const routes = mainRoutes.map((route) => {
    if (route.text === "Saylov") {
      return { ...route, items: electionData };
    }
    return route;
  });

  return (
    <div className="hamburge_menu">
      <div style={{ position: "relative", zIndex: 10001 }}>
        <Turn toggled={isOpen} toggle={setIsOpen} />
      </div>

      {isOpen && (
        <div
          data-aos="fade-down"
          style={isOpen ? { transform: "translateX(0)" } : null}
          className="hamburge_menu_wrapper"
        >
          <div
            data-aos="fade-down"
            className="content_wrapper hamburge_menu_container"
          >
            <div className="navbar_menu_items">
              <div className="hamburge_menu_item_container_title">
                {t("Menu")}
              </div>
              <div className="divider"></div>
              <div className="divider_margin"></div>
              {routes.map((item, index) => {
                return (
                  item.visibleInNavbar && (
                    <div key={`362${index}`}>
                      {item?.items ? (
                        <Dropdown
                          menu={{
                            items: item.items.map((subItem) => ({
                              ...subItem,
                              label: (
                                <p className="navbat_dropdown_item">
                                  {t(subItem.label.props.children)}
                                </p>
                              ),
                            })),
                            onClick,
                          }}
                        >
                          <div key={index} className="navbar_menu_item">
                            {t(item.text)} <Icon icon="mingcute:down-fill" />
                          </div>
                        </Dropdown>
                      ) : (
                        <div
                          key={index}
                          className="navbar_menu_item"
                          onClick={() => onClickTitle(item.path)}
                        >
                          {t(item.text)}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
              <Link to="/#donation" className="navbar_menu_item">
                {t("sponsorship")}
              </Link>
            </div>
            {navbarRoutes.map((navbarItem) => {
              return (
                <div
                  key={navbarItem.id}
                  className="hamburge_menu_item_container"
                >
                  {/* <div className="hamburge_menu_item_container_title">
                    {t(navbarItem.title)}
                  </div> */}
                  <div className="divider"></div>
                  <div className="hamburge_menu_item_container">
                    {navbarItem.subItems.map((subItem) => {
                      return (
                        <a
                          href={subItem?.href}
                          target="_blank"
                          rel="noreferrer"
                          className="hamburge_menu_item_container_list_title"
                          key={subItem.id}
                          onClick={() => {
                            if (subItem?.subRoute) {
                              onClickTitle(subItem.subRoute);
                            }
                          }}
                        >
                          {t(subItem.title)}
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgeMenu;
