import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HelpUsCard = ({ id, image, title, description, href, navigation }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <a
      data-aos="fade-down"
      data-aos-duration="2000"
      className="help_us_card"
      key={id}
      onClick={() => navigate(navigation)}
      href={href}
      target={id === 1 ? "_self" : "_blank"}
      rel="noreferrer"
    >
      <img className="help_us_card_image" src={image} alt="" />
      <div className="help_us_card_box">
        <p className="help_us_card_title">{t(title)}</p>
        <p className="help_us_card_description">{t(description)}</p>
      </div>
    </a>
  );
};

export default HelpUsCard;
