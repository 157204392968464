import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const SectionTitle = ({
  title,
  color,
  onClick,
  secondTitle,
  secondColor,
  onSecondClick,
}) => {
  const { t } = useTranslation();

  return (
    <div data-aos="fade-down" className="titles_wrapper">
      <div
        onClick={onClick}
        style={{
          color: color,
          cursor: onClick ? "pointer" : "default",
        }}
        className="section_title"
      >
        {t(title)}
      </div>
      {secondTitle && <div className="vertical_divider"></div>}
      {secondTitle && (
        <div
          onClick={onSecondClick}
          style={{
            color: color,
            cursor: onSecondClick ? "pointer" : "default",
          }}
          className="section_title"
        >
          <span style={{ color: secondColor }}>{t(secondTitle)}</span>
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
