import Articles from "../../../articles";
import CentralLeadership from "../../../centralLeadership";
import Donation from "../../../donation";
import EShop from "../../../eShop";
// import Election from "../../../election";
import History from "../../../history";
import Home from "../../../home";
import RegionDetails from "../../../home/regionDetails";
import TerritorialDivision from "../../../home/territorialDivisionDetails";
import News from "../../../news";
import ContentDetails from "../../page/contentDetails";
import Program from "../../../program";
import Projects from "../../../projects";
import Volunteers from "../../../volunteers";
import Reception from "../../../reception";
import Advices from "../../../advices";
import Contact from "../../../contact";
import YouthParliament from "../../../youthParliament";
import MostCommentedNews from "../../../mostCommentedNews";
import MostCommentedNewsDetails from "../../../mostCommentedDetails";
import LeadershipDetails from "../../../leadershipDetails";
import Council from "../../../council";
import Senat from "../../../senat";
import VideoGallery from "../../../videoGallery";
import PhotoGallery from "../../../photoGallery";
import AdvicesDetails from "../../../advicesDetails";
import Deputes from "../../../deputes";
import DeputesDetails from "../../../deputesDetails";

export const mainRoutes = [
  {
    text: "Asosiy",
    path: "/",
    exact: true,
    visibleInNavbar: false,
    element: <Home />,
  },
  {
    text: "Biz haqimizda",
    exact: true,
    visibleInNavbar: true,
    items: [
      {
        key: "/history",
        label: <p className="navbat_dropdown_item">Partiya tarixi</p>,
        element: <History />,
      },
      {
        key: "/programm",
        label: <p className="navbat_dropdown_item">Dastur</p>,
        element: <Program />,
      },
      // {
      //   key: "/election-program",
      //   label: <p className="navbat_dropdown_item">Saylovoldi dasturi</p>,
      //   element: <Election />,
      // },
      {
        key: "/central-leadership",
        label: <p className="navbat_dropdown_item">Rahbariyat</p>,
        element: <CentralLeadership />,
      },
    ],
  },
  {
    text: "Yangiliklar",
    path: "/news",
    exact: true,
    visibleInNavbar: false,
    element: <News navigatePath="/news" />,
  },

  {
    text: "Saylov",
    exact: true,
    visibleInNavbar: true,
    items: [
      {
        key: "/document",
        label: (
          <p className="navbat_dropdown_item">
            Oliy Majlis deputatligiga nomzodlar
          </p>
        ),
        element: <Deputes />,
      },
    ],
  },
  {
    text: `Oliy Majlis deputatligiga nomzodlar`,
    path: "/document/:id",
    exact: true,
    visibleInNavbar: false,
    element: <DeputesDetails />,
  },
  {
    text: `Fuqarolar qabuli`,
    path: "/reception",
    exact: true,
    visibleInNavbar: true,
    element: <Reception />,
  },
  {
    text: "E-shop",
    path: "/shop",
    exact: true,
    visibleInNavbar: true,
    element: <EShop />,
  },
  {
    text: "Aloqa",
    path: "/contact",
    exact: true,
    visibleInNavbar: true,
    element: <Contact />,
  },
  {
    text: "Buyurtma berish",
    path: "/checkout",
    exact: true,
    visibleInNavbar: false,
    element: <Donation />,
  },
  {
    text: `Kirish`,
    path: "/login",
    exact: true,
    visibleInNavbar: false,
    element: <div>Kirish</div>,
  },
  {
    text: `Territory detail`,
    path: "/territorial-division/:divisionId",
    exact: true,
    visibleInNavbar: false,
    element: <TerritorialDivision />,
  },
  {
    text: `Viloyatlar`,
    path: "/regions/:regionName",
    exact: true,
    visibleInNavbar: false,
    element: <RegionDetails />,
  },
  {
    text: `Volonteers`,
    path: "/volunteers",
    exact: true,
    visibleInNavbar: false,
    element: <Volunteers />,
  },
  {
    text: `Projects`,
    path: "/projects",
    exact: true,
    visibleInNavbar: false,
    element: <Projects />,
  },
  {
    text: `Articles`,
    path: "/articles",
    exact: true,
    visibleInNavbar: false,
    element: <Articles />,
  },
  {
    text: `News detail`,
    path: "/news/:newsId",
    exact: true,
    visibleInNavbar: false,
    element: <ContentDetails />,
  },
  {
    text: `Rahbariyat`,
    path: "/central-leadership/:id",
    exact: true,
    visibleInNavbar: false,
    element: <LeadershipDetails />,
  },
  {
    text: `E'lonlar`,
    path: "/advertisements",
    exact: true,
    visibleInNavbar: false,
    element: <Advices />,
  },
  {
    text: `E'lonlar`,
    path: "/advertisements/:adviceId",
    exact: true,
    visibleInNavbar: false,
    element: <AdvicesDetails />,
  },
  {
    text: `Yoshlar parlamenti`,
    path: "/youth-parliament",
    exact: true,
    visibleInNavbar: false,
    element: <YouthParliament />,
  },
  {
    text: `Muhokama bogan yangiliklar`,
    path: "/most-commented",
    exact: true,
    visibleInNavbar: false,
    element: <MostCommentedNews />,
  },
  {
    text: `Muhokama bogan yangiliklar`,
    path: "/most-commented/:newsId",
    exact: true,
    visibleInNavbar: false,
    element: <MostCommentedNewsDetails />,
  },
  {
    text: `Markaziy kengash`,
    path: "/central-council",
    exact: true,
    visibleInNavbar: false,
    element: <Council />,
  },
  {
    text: `Senator kengash`,
    path: "/senator",
    exact: true,
    visibleInNavbar: false,
    element: <Senat />,
  },
  {
    text: `Video gallery`,
    path: "/video-gallery",
    exact: true,
    visibleInNavbar: false,
    element: <VideoGallery />,
  },
  {
    text: `Photo gallery`,
    path: "/photo-gallery",
    exact: true,
    visibleInNavbar: false,
    element: <PhotoGallery />,
  },
  {
    text: "404",
    path: "*",
    visibleInNavbar: false,
    exact: true,
    element: <div>404 not found</div>,
  },
];
