import React from "react";
import { useTranslation } from "react-i18next";

import SocialMedias from "../socialMedias";

import mainVideo from "./../../shared/assets/main_video.MOV";

import "./style.css";

const Main = () => {
  const { t } = useTranslation();

  return (
    <div className="main_wrapper">
      <video className="background-video" autoPlay loop muted>
        <source src={mainVideo} type="video/mp4" />
      </video>
      <div className="main_opener">
        <div className="main_button_wrapper ">
          <div></div>
          <a
            href="https://azolik.ecouz.uz/"
            target="_blank"
            data-aos="fade-right"
            rel="noreferrer"
            className="main_button"
          >
            {t("becomeMember")}
          </a>
        </div>
      </div>
      <SocialMedias />
    </div>
  );
};

export default Main;
