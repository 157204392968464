import React from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../shared/utils/numberUtils";

const NewsCard = ({
  image,
  description,
  views,
  postedDate,
  descriptionClassName,
  imageClassName,
  cardInfoClassName,
  onClick,
}) => {
  const { t } = useTranslation();

  const replaceWithDots = (text) => {
    if (text?.length > 80) {
      return text.slice(0, 80) + "...";
    }
    return text;
  };

  return (
    <div onClick={onClick} data-aos="fade-up" className="news_box_card">
      <img
        className={`news_card_image ${imageClassName}`}
        src={image}
        alt="news"
      />
      <div className={`news_card_info ${cardInfoClassName}`}>
        <p className="news_posted_date">
          <Icon width={"17px"} icon="mdi:calendar" />
          {postedDate}
        </p>
        <p className="news_views">
          <Icon width={"17px"} icon="mdi:eye" />
          {formatNumber(views)}
        </p>
      </div>
      <p className={`news_description ${descriptionClassName}`}>
        {replaceWithDots(description)}
      </p>
      <div onClick={onClick} className="more_news_navigator">
        {t("Batafsil")} <Icon icon="bi:arrow-right" />
      </div>
    </div>
  );
};

export default NewsCard;
