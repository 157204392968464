import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import { mostCommentedNewsData } from "../mostCommentedNews/utils/mostCommentedNewsData";

import "react-photo-view/dist/react-photo-view.css";

import NewsCard from "../home/news/components/NewsCard";

const MostCommentedNewsDetails = () => {
  const { newsId } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState();

  useEffect(() => {
    const data = mostCommentedNewsData?.find(
      (value) => value?.id === Number(newsId)
    );
    setState(data);
  }, [newsId]);

  return (
    <div className="content_details_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper content_details_container"
      >
        <div className="main_content_details_wrapper">
          <div className="content_posted_info">
            <p className="news_posted_date">{state?.postedDate}</p>|
            <p className="news_views">
              <Icon icon="mdi:eye" />
              {state?.views}
            </p>
          </div>
          <p className="content_details_title">
            {state?.[`title_${i18n.language}`]}
          </p>
          <PhotoProvider
            easing={(type) =>
              type === 2
                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
          >
            <PhotoView src={state?.image}>
              <img
                className="content_details_image"
                src={state?.image}
                alt={`contentImages`}
              />
            </PhotoView>
          </PhotoProvider>
          <div className="content_details_description">
            {state?.[`description_${i18n.language}`]}
          </div>
        </div>
        <div className="extra_info">
          <div className="subscribe_telegram_card">
            <Icon width="30px" icon={"iconoir:telegram"} />
            <p>{t("telegramSubscribe")}</p>
            <a
              href="https://t.me/ecopartyuzb"
              target="_blank"
              rel="noreferrer"
              className="subscribe_telegram_button"
            >
              {t("becomeMember")}
            </a>
          </div>
          <div className="extra_info_news_card">
            {mostCommentedNewsData
              ?.filter((value) => value?.id != newsId)
              .slice(1, 4)
              .map((news, index) => {
                return (
                  <NewsCard
                    key={index}
                    cardInfoClassName={"extra_news_card_info"}
                    image={news.image}
                    description={news?.[`title_${i18n.language}`]}
                    views={news?.views}
                    postedDate={news?.postedDate}
                    descriptionClassName={"extra_news_card_info_title"}
                    onClick={() => navigate(`/most-commented/${news?.id}`)}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostCommentedNewsDetails;
