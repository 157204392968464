import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "../shared/components/loader";
import SectionTitle from "../shared/components/sectionTitle";
import NewsCard from "../home/news/components/NewsCard";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const tips = [
  {
    category: "Tabiiy resurslarni tejash",
    items: [
      "Suvni tejash: Dushga tezroq tushing, suvni tekkanida o'chirib qo'ying. O'simliklarni kechki paytda sug'orish suvni tejashga yordam beradi.",
      "Elektr energiyasini tejash: Quyosh nuridan ko'proq foydalaning, foydalanilmayotgan qurilmalarning elektrini o'chirib qo'ying. Energiya samarador lampalardan foydalaning.",
    ],
  },
  {
    category: "Axlatni qayta ishlash",
    items: [
      "Qayta ishlash: Plastik, qog'oz, shisha va metallni qayta ishlash uchun alohida idishlardan foydalaning.",
      "Qayta ishlatish: Eski kiyimlar, o'yinchoqlar va boshqa narsalarni qayta ishlatish yoki xayriya qilish.",
    ],
  },
  {
    category: "Ekologik toza mahsulotlardan foydalanish",
    items: [
      "Ekologik toza uskunalar: Kam energiya sarflaydigan maishiy texnikadan foydalaning.",
      "Organik mahsulotlar: Kimyoviy moddalarsiz yetishtirilgan mahsulotlardan foydalaning.",
    ],
  },
  {
    category: "Atrof-muhitni himoya qilish",
    items: [
      "Daraxt o'tqazish: Har yili daraxt o'tqazish kampaniyalarida qatnashing.",
      "Faol ishtirok etish: Toza hayvonot bog'lari va tabiatni muhofaza qilish hududlarida ixtiyoriy qatnashing.",
    ],
  },
  {
    category: "Transportdan oqilona foydalanish",
    items: [
      "Jamoat transporti: Imkon qadar jamoat transportidan foydalaning yoki birgalikda yo'lda yurishni tashkil qiling.",
      "Velosiped va piyoda yurish: Yig'in va ishga velosipedda yoki piyoda yuring.",
    ],
  },
  {
    category: "Axlatni kamaytirish",
    items: [
      "Muddatsiz mahsulotlar: Plastik paketlarning o'rniga mato sumkalardan foydalaning.",
      "Yangi narsalarni kamroq sotib oling: Keraksiz mahsulotlarni sotib olishdan saqlaning.",
    ],
  },
  {
    category: "Hayvonot va o'simliklarni himoya qilish",
    items: [
      "Qo'riqxonalar va himoya qilingan hududlar: Tabiiy hududlarni muhofaza qilish ishlarida ishtirok eting.",
      "Hayvonot dunyosiga ehtiyotkorlik: Hayvonot dunyosiga zarar yetkazmaslik uchun faoliyatingizni qayta ko'rib chiqing.",
    ],
  },
  {
    category: "Ilm va ta'lim",
    items: [
      "Ma'lumotlar tarqatish: Yaqinlaringizga va do'stlaringizga ekologik muammolar haqida ma'lumot bering.",
      "Ekota'lim: Maktablarda va universitetlarda ekologik ta'lim berish.",
    ],
  },
];

const Advices = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, data } = usePageFetcher("advice/");

  return (
    <div className="advices_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper advices_container"
      >
        <SectionTitle title={"E'lonlar"} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="news_box">
            {data.map((tip, index) => {
              return (
                <NewsCard
                  key={123 + index}
                  image={tip.image}
                  description={tip[`title_${i18n.language}`]}
                  views={tip.views || 1}
                  postedDate={tip.postedDate || "21.09.2024"}
                  descriptionClassName={"all_news_description"}
                  imageClassName={"all_news_image"}
                  onClick={() => navigate(`/advertisements/${tip?.id}`)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Advices;
