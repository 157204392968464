import React from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import { socialMediasIcons } from "../../utils/socialMediasIcons";
import { formatLocalizedDateTime } from "../../utils/dateTimeUtils";

import "./style.css";

import mainLogo from "./../../assets/mainLogo.png";

const Footer = () => {
  const { t } = useTranslation();
  const renderSocialMediaLink = {
    CEO: [
      "https://t.me/A_Xamzayev",
      "https://facebook.com/abdushukur.hamzaev",
      "https://instagram.com/abdushukurhamzaev",
      "https://youTube.com/c/ecouz",
    ],
    OEP: [
      "https://t.me/ecopartyuzb",
      "https://facebook.com/ecopartyuzb",
      "https://instagram.com/ecopartyuzb",
      "https://youTube.com/c/ecouz",
    ],
  };

  return (
    <div className="footer_wrapper">
      <div className="content_wrapper footer_container">
        <div className="footer_section">
          <img className="footer_image" src={mainLogo} alt="" />
          <p className="footer_image_text">{formatLocalizedDateTime()}</p>
        </div>
        <div className="footer_section">
          <div className="about_box">{t("Sayt haqida")}</div>
          <a
            href="https://ecouz.uz"
            target="_blank"
            rel="noreferrer"
            className="footer_info"
          >
            https://ecouz.uz
          </a>
          <a href="mailto:info@ecouz.uz" className="footer_info">
            info@ecouz.uz
          </a>
          <a
            href="tel:+998955115000"
            className="footer_phone_number working_time"
          >
            +998 (95) 511 50 00
          </a>
          <a
            href="tel:+998955125000"
            className="footer_phone_number working_time"
          >
            +998 (95) 512 50 00
          </a>
        </div>
        <div className="footer_section">
          <div className="about_box">{t("Manzil")}</div>
          <p className="footer_info">{t("ourAddress")}</p>
          <p className="working_time">
            <span>{t("working")}</span> {t("workingDays")}
          </p>
          <p className="working_time">
            <span>{t("workingTimes")}</span> 09:00 - 18:00
          </p>
        </div>

        <div className="footer_section">
          <p className="footer_bold_name">{t("partyLider")}</p>
          <div className="footer_social_media_wrapper">
            {socialMediasIcons.map(({ id, icon }, index) => {
              return (
                <a
                  href={renderSocialMediaLink["CEO"][index]}
                  target="_blank"
                  className="footer_social_media"
                  key={id}
                  rel="noreferrer"
                >
                  <Icon width={"24px"} icon={icon} />
                </a>
              );
            })}
          </div>
          <p className="footer_bold_name">{t("Biz ijtimoiy tarmoqlarda")}</p>
          <div className="footer_social_media_wrapper">
            {socialMediasIcons.map(({ id, icon }, index) => {
              return (
                <a
                  href={renderSocialMediaLink["OEP"][index]}
                  target="_blank"
                  className="footer_social_media"
                  key={id}
                  rel="noreferrer"
                >
                  <Icon width={"24px"} icon={icon} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
