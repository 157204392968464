import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import SectionTitle from "../../shared/components/sectionTitle";

import { updateEco } from "../../repository/eco-backend";

import "./style.css";

const ContactUs = () => {
  const { t } = useTranslation();
  const [nameMessage, setNameMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      formData.append(
        "text",
        `UZ: Yangi habar keldi:\nIsm: ${nameMessage} , telefon raqam: ${emailMessage}, xabar: ${message}.\nРу: Пришло новоё сообщение:\nИмя: ${nameMessage}, телефон номер: ${emailMessage}, сообщение: ${message}.`
      );

      await updateEco("post", "send-message-tg/", formData);

      setNameMessage("");
      setEmailMessage("");
      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="contact_us_wrapper">
      <form onSubmit={handleSubmit}>
        <div
          style={{ alignItems: "start" }}
          className="contact_us_container content_wrapper"
        >
          <SectionTitle title={"MUROJAAT QILING"} />
          <div className="contact_us_box_wrapper">
            <div className="contact_us_inputs_wrapper">
              <div className="contact_us_inputs">
                <input
                  type="text"
                  placeholder={t("yourName")}
                  className="contact_us_input"
                  value={nameMessage}
                  onChange={(e) => setNameMessage(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder={t("yourEmail")}
                  className="contact_us_input"
                  value={emailMessage}
                  onChange={(e) => setEmailMessage(e.target.value)}
                  required
                />
              </div>
              <textarea
                className="contact_us_area"
                rows="10"
                placeholder={t("message")}
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button className="contact_us_button" type="submit">
                {t("sendMessage")}
              </button>
            </div>
            <div className="contact_telegram_bot">
              <a
                href="https://t.me/ecopartyuzb_bot"
                target="_blank"
                rel="noreferrer"
                className="contact_telegram_bot_wrapper"
              >
                <p className="contact_telegram_bot_text">
                  {t("contactTelegram")}
                </p>
                <Icon width="130px" icon="logos:telegram" />
              </a>
              <a
                href="https://t.me/ecopartyuzb_bot"
                target="_blank"
                rel="noreferrer"
                className="contact_us_button"
              >
                {t("redirectToBot")}
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
