import React from "react";

import SectionTitle from "../../shared/components/sectionTitle";
import DeputiesCarousel from "./components/DeputiesCarousel";

import "./style.css";

const Deputies = () => {
  return (
    <div className="deputies_carousel_wrapper">
      <SectionTitle color={"#0A5F22"} title={"SIZNING DEPUTATINGIZ"} />
      <DeputiesCarousel />
    </div>
  );
};

export default Deputies;
