import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Keyboard, Autoplay } from "swiper/modules";
import SectionTitle from "../../shared/components/sectionTitle";

import { partnershipData } from "./utils/partnershipData";

import "./style.css";

const teamSlider = {
  spaceBetween: 20,
  navigation: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
};

export const Partnership = () => {
  return (
    <div id="partner" className="partnership_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper partnership_container"
      >
        <SectionTitle title="HAMKORLARIMIZ" />
        <Swiper
          data-aos="fade-up"
          {...teamSlider}
          loop={true}
          slidesPerView={3}
          spaceBetween={30}
          keyboard={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation, Keyboard, Autoplay]}
          className="mySwiper"
        >
          {partnershipData.map((value, index) => {
            return (
              <SwiperSlide key={index + 8428} className="partnership_box">
                <a href={value.path} target="_blank">
                  <img className="partnership_img" src={value.img} alt="" />
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
