import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import Loader from "../shared/components/loader";

import { formatLocalizedDateTime } from "../shared/utils/dateTimeUtils";
import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const AirQuality = () => {
  const { isLoading, data } = usePageFetcher("air-quality/");
  const { t } = useTranslation();
  const [airQuality, setAirQuality] = useState(null);
  const [color, setColor] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data?.data) {
      setAirQuality(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (airQuality) {
      const aqi = airQuality?.current?.pollution?.aqius;
      if (aqi !== undefined) {
        if (aqi <= 50) setColor("#A8E05F");
        else if (aqi <= 100) setColor("#FFDF58");
        else if (aqi <= 150) setColor("#FE9B57");
        else if (aqi <= 200) setColor("#FD6A6A");
        else if (aqi > 200) setColor("#A070B6");
      }
    }
  }, [airQuality]);

  const renderIcon = (aqi) => {
    if (aqi <= 50)
      return <Icon icon={"mdi:face-happy"} style={{ fontSize: "48px" }} />;
    if (aqi <= 100)
      return <Icon icon={"mdi:face-neutral"} style={{ fontSize: "48px" }} />;
    if (aqi <= 150)
      return <Icon icon={"mdi:face-sad"} style={{ fontSize: "48px" }} />;
    if (aqi <= 200)
      return <Icon icon={"mdi:face-sad"} style={{ fontSize: "48px" }} />;
    if (aqi > 200)
      return <Icon icon={"mdi:face-cry"} style={{ fontSize: "48px" }} />;
  };

  const renderWeatherIcon = (condition) => {
    const weatherIcons = {
      "01d": "mdi:weather-sunny",
      "01n": "mdi:weather-sunny",
      "02d": "mdi:weather-partly-cloudy",
      "02n": "mdi:weather-partly-cloudy",
      "03d": "mdi:weather-cloudy",
      "03n": "mdi:weather-cloudy",
      "04d": "mdi:weather-cloudy",
      "04n": "mdi:weather-cloudy",
      "09d": "mdi:weather-rainy",
      "09n": "mdi:weather-rainy",
      "10d": "mdi:weather-pouring",
      "10n": "mdi:weather-pouring",
      "11d": "mdi:weather-lightning",
      "11n": "mdi:weather-lightning",
      "13d": "mdi:weather-snowy",
      "13n": "mdi:weather-snowy",
      "50d": "mdi:weather-fog",
      "50n": "mdi:weather-fog",
    };

    return (
      <Icon
        color="#879BB2"
        icon={weatherIcons[condition] || "mdi:weather-sunny-alert"}
        style={{ fontSize: "25px" }}
      />
    );
  };

  if (error) {
    return <div>{t("Ups, xatolik yuz berdi")}</div>;
  }

  

  return (
    <div className="air_information_wrapper" data-aos="fade-up">
      {isLoading ? (
        <Loader />
      ) : (
        airQuality && (
          <div>
            <h2>{t(airQuality.state)}</h2>
            <p className="last_updatedtime">
              {t("lastUpdate")}:{" "}
              {formatLocalizedDateTime(airQuality?.current.pollution.ts)}
            </p>
            <div style={{ background: color }} className="air_aqi_displayer">
              {renderIcon(airQuality?.current?.pollution?.aqius)}
              <p>AQI: {airQuality?.current?.pollution?.aqius}</p>
            </div>
            <div className="weather_information_wrapper">
              <div className="weather_information">
                {renderWeatherIcon(airQuality?.current?.weather?.ic)}
                <p>{airQuality?.current?.weather?.tp}°C</p>
              </div>
              |
              <div className="weather_information">
                <Icon
                  icon="mdi:water"
                  color="#879BB2"
                  style={{ fontSize: "25px" }}
                />
                <p>{airQuality?.current?.weather?.hu}%</p>
              </div>
              |
              <div className="weather_information">
                <Icon
                  icon="mdi:navigation-variant"
                  style={{ fontSize: "25px" }}
                  color="#879BB2"
                />
                <p>{airQuality?.current?.weather?.ws} m/s</p>
              </div>
            </div>
            <a
              href="https://www.iqair.com/ru/uzbekistan/toshkent-shahri/tashkent"
              target="_blank"
              className="navigate_to_iq_air"
              rel="noreferrer"
            >
              {t("goToAir")}
            </a>
          </div>
        )
      )}
    </div>
  );
};

export default AirQuality;
