import React from "react";

import SectionTitle from "../../shared/components/sectionTitle";
import HelpUsCard from "./components/HelpUsCard";

import { helpUsData } from "./utils/helpUsData";

import "./style.css";

const HelpUs = () => {
  return (
    <div className="help_us_wrapper">
      <div className="content_wrapper help_us_container">
        <SectionTitle color="#ffffff" title="QANDAY KO`MAKLASHA OLASIZ ?" />
        <div className="help_us_card_wrapper">
          <div className="help_us_card_wrapper_left">
            {helpUsData.slice(0, 2).map((helpUs, index) => {
              return (
                <HelpUsCard
                  key={index + 42883}
                  id={helpUs.id}
                  image={helpUs.image}
                  description={helpUs.description}
                  title={helpUs.title}
                  href={helpUs.href}
                  navigation={helpUs.navigate}
                />
              );
            })}
          </div>
          <div className="help_us_card_wrapper_right">
            {
              <HelpUsCard
                id={helpUsData[2].id}
                image={helpUsData[2].image}
                description={helpUsData[2].description}
                title={helpUsData[2].title}
                href={helpUsData[2].href}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpUs;
