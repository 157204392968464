import payme from "./../../../shared/assets/donationImages/payme.png";
import click from "./../../../shared/assets/donationImages/click.png";
import paynet from "./../../../shared/assets/donationImages/paynet.png";
import uzum from "./../../../shared/assets/donationImages/uzum.png";
import qrPayme from "./../../../shared/assets/donationImages/qrClick.png";
import qrClick from "./../../../shared/assets/donationImages/qrPayme.png";
import qr from "./../../../shared/assets/donationImages/qr.png";

export const donationImages = [
  { id: 1, qrImage: qrPayme, image: payme },
  { id: 2, qrImage: qrClick, image: click },
  { id: 3, qrImage: qr, image: paynet },
  { id: 4, qrImage: qr, image: uzum },
];
