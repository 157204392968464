import React from "react";

import SectionTitle from "../shared/components/sectionTitle";
import ECOCard from "../shared/components/card";

import { projectsData } from "./utils/projectsData";

import "./style.css";

const Projects = () => {
  return (
    <div className="project_wrapper">
      <div className="content_wrapper project_container">
        <SectionTitle title={"Loyihalar"} />
        <div className="project_card_wrapper">
          {projectsData.map((project) => {
            return (
              <ECOCard
                image={project.image}
                title={project.title}
                description="Toshkent shahrida O‘zbekiston Respublikasi Milliy gvardiyasi boshqarmasi va O‘zbekiston Polo fediratsiyasi hamkorligida ot sportining Polo turi bo‘yicha musobaqa o‘tkazildi"
                date={`0${project.id}.02.2020`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
