import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./style.css";

const Articles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="article_wrapper">
      <div className="content_wrapper article_container">
        <a
          href="https://online.anyflip.com/mtjjn/keja/mobile/index.html"
          className="party_newspaper"
          target="_blank"
          rel="noreferrer"
        >
          <p className="article_title">{t("PARTIYA GAZETASI")}</p>
        </a>
        <div onClick={() => navigate("/articles")} className="party_articles">
          <p className="article_title">{t("MAQOLALAR")}</p>
        </div>
      </div>
    </div>
  );
};

export default Articles;
