import React from "react";
import { Icon } from "@iconify/react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import AirQuality from "../../aqAir";

import usePageFetcher from "../../repository/usePageFetcher";

import "./style.css";

const CountUpOnView = ({ end = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView ? (
        <CountUp end={Number(end) || 0} duration={2} />
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

const AdditionalInfo = () => {
  const { isLoading, data } = usePageFetcher("about/");
  const { t } = useTranslation();

  const additionalInfoData = [
    {
      id: 1,
      title: "A’zolar",
      number: data?.[0]?.members,
      icon: "fluent:people-team-20-filled",
    },
    {
      id: 2,
      title: "BPT soni",
      number: data?.[0]?.party_organization,
      icon: "fluent:people-team-20-filled",
    },
  ];

  return (
    <div className="additional_info_wrapper">
      <div className="content_wrapper additional_info_details">
        <div
          style={{ alignItems: "center" }}
          className="content_wrapper additional_info_container"
        >
          {additionalInfoData.map((value) => {
            return (
              <div
                data-aos="fade-up"
                className="additional_info"
                key={value.id}
              >
                <Icon icon={value.icon} width={"70px"} />
                <div className="additional_info_bottom">
                  <CountUpOnView end={value.number} />
                  <p>{t(value.title)}</p>
                </div>
              </div>
            );
          })}
        </div>
        <AirQuality />
      </div>
    </div>
  );
};

export default AdditionalInfo;
