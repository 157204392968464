import React from "react";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";

const Senat = () => {
  const { t, i18n } = useTranslation();
  const { isLoading, data } = usePageFetcher("senator/");

  return (
    <div className="central_leadership">
      <div className="content_wrapper central_leadership_container">
        <SectionTitle title={t("Partiyaning Oliy Majlis Senati a'zolari")} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="leadership_grid">
            {data?.map((data, index) => (
              <div className="leadership_card" key={index} data-aos="fade-up">
                <img
                  src={data.img}
                  alt={data.name}
                  className="leadership_photo"
                  effect="blur"
                />
                <div className="leadership_info">
                  <h3 className="leadership_name">
                    {data[`name_${i18n.language}`]}
                  </h3>
                  <p className="leadership_job">
                    {data[`job_${i18n.language}`]}
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Faks")}:</strong> <span>{data?.fax}</span>
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Telefon raqam")}:</strong>{" "}
                    <span>{data?.phone_number}</span>
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Email")}:</strong> <span>{data?.email}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Senat;
