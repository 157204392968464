import deputyImage1 from "./../assets/employersImage/1.png";
import deputyImage2 from "./../assets/employersImage/2.png";
import deputyImage3 from "./../assets/employersImage/3.png";
import deputyImage4 from "./../assets/employersImage/4.png";
import deputyImage5 from "./../assets/employersImage/5.png";
import deputyImage6 from "./../assets/employersImage/6.png";
import deputyImage7 from "./../assets/employersImage/7.png";
import deputyImage8 from "./../assets/employersImage/8.png";
import deputyImage9 from "./../assets/employersImage/9.png";
import deputyImage10 from "./../assets/employersImage/10.png";
import deputyImage11 from "./../assets/employersImage/11.png";
import deputyImage12 from "./../assets/employersImage/12.png";
import deputyImage13 from "./../assets/employersImage/13.png";
// import deputyImage14 from "./../assets/employersImage/14.png";

import toshkent from "./../assets/regionsImage/toshkent.png";
import qashqadaryo from "./../assets/regionsImage/qashqadaryo.png";
import xorazm from "./../assets/regionsImage/xorazm.png";
import fargona from "./../assets/regionsImage/fargona.png";
import surxandaryo from "./../assets/regionsImage/surxandaryo.png";
// import samarqand from "./../assets/regionsImage/samarqand.png";
import namangan from "./../assets/regionsImage/namangan.jpg";
import navoiy from "./../assets/regionsImage/navoiy.jpg";
import jizzax from "./../assets/regionsImage/jizzax.png";
import buxoro from "./../assets/regionsImage/buxoro.png";
import andijon from "./../assets/regionsImage/andijon.png";
import qoraqalpokiston from "./../assets/regionsImage/qoraqalpokiston.png";
import sirdaryo from "./../assets/regionsImage/sirdaryo.JPG";
import toshkentViloyati from "./../assets/regionsImage/toshkentV.png";

export const territorialDivisionData = [
  {
    id: 1,
    title_uz: "Toshkent shahri",
    title_ru: "Город Ташкент",
    title_en: "Tashkent City",
    image: toshkent,
    deputyName_uz: "Fayziyeva Sevara Tulkunovna",
    deputyName_ru: "Файзиева Севара Тулкуновна",
    deputyName_en: "Sevara Tulkunovna Fayziyeva",
    job_uz:
      "Toshkent shahri partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации города Ташкент.",
    job_en:
      "Chairman of the Executive Committee of the City Party Organization of Tashkent.",
    email: "toshkentsh@ecouz.uz",
    phoneNumber: "(95) 603-01-01",
    address_uz: "Toshkent shahri, Yakkasaroy tumani Abdulla Qahhor ko‘chasi 2B",
    address_ru: "Город Ташкент, район Яккасарай, улица Абдулла Каххар 2Б",
    address_en: "Tashkent City, Yakkasaray District, Abdulla Qahhor Street 2B",
    nearAddress_uz: "Yakkasaroy tumani Istanbul siti",
    nearAddress_ru: "Район Яккасарай, Сити Стамбул",
    nearAddress_en: "Yakkasaray District, Istanbul City",
    deputyImage: deputyImage1,
  },
  {
    id: 2,
    title_uz: "Qashqadaryo viloyati",
    title_ru: "Область Кашкадарья",
    title_en: "Kashkadarya Region",
    image: qashqadaryo,
    deputyName_uz: "Xalimov Nosir Xakimovich",
    deputyName_ru: "Халимов Носир Хакимович",
    deputyName_en: "Nosir Hakimovich Khalimov",
    job_uz:
      "Qashqadaryo viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Кашкадарья.",
    job_en:
      "Chairman of the Executive Committee of the Kashkadarya Regional Party Organization.",
    email: "qashqadaryo@ecouz.uz",
    phoneNumber: "(95) 341-70-07",
    address_uz:
      "Qashqadaryo viloyati, Qarshi shahri Olchin ko‘chasi 2-uyda joylashgan.",
    address_ru: "Область Кашкадарья, город Карши, улица Ольчин, дом 2.",
    address_en: "Kashkadarya Region, City of Karshi, Olchin Street, House 2.",
    nearAddress_uz:
      "Qarshi shahar, viloyat Maktabgacha va Maktab ta’limi birinchi qavatida.",
    nearAddress_ru:
      "Город Карши, первый этаж областного дошкольного и школьного образования.",
    nearAddress_en:
      "Karshi City, First Floor of the Regional Preschool and School Education Department.",
    deputyImage: deputyImage2,
  },
  {
    id: 3,
    title_uz: "Xorazm viloyati",
    title_ru: "Область Хорезм",
    title_en: "Khorezm Region",
    image: xorazm,
    deputyName_uz: "Urazboyev Akmal Aminbayevich",
    deputyName_ru: "Уразбоев Акмал Аминбаевич",
    deputyName_en: "Akmal Aminbayevich Urazboyev",
    job_uz:
      "Xorazm viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Хорезм.",
    job_en:
      "Chairman of the Executive Committee of the Khorezm Regional Party Organization.",
    email: "xorazm@ecouz.uz",
    phoneNumber: "(95) 475 90-90",
    address_uz:
      "Xorazm viloyati Urganch shahri O‘zbekiston ko‘chasi 31/1 NNT lar uyi",
    address_ru:
      "Область Хорезм, город Ургенч, улица Узбекистан, дом 31/1, ННТ.",
    address_en:
      "Khorezm Region, Urgench City, Uzbekistan Street 31/1 NNT House",
    nearAddress_uz:
      "Xorazm viloyati Urganch shahri Qurilish kasb-hunar kolleji ro‘parasida.",
    nearAddress_ru: "Город Ургенч, напротив колледжа строительных профессий.",
    nearAddress_en:
      "Urgench City, Opposite the Construction Vocational College.",
    deputyImage: deputyImage3,
  },
  {
    id: 4,
    title_uz: "Toshkent viloyati",
    title_ru: "Область Ташкент",
    title_en: "Tashkent Region",
    image: toshkentViloyati,
    deputyName_uz: "Axmedjanov Furqatjon Raximjanovich",
    deputyName_ru: "Ахмеджанов Фуркатжон Рахимжанович",
    deputyName_en: "Furqatjon Rakhimjanovich Ahmedjanov",
    job_uz:
      "Toshkent viloyat partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Ташкент.",
    job_en:
      "Chairman of the Executive Committee of the Tashkent Regional Party Organization",
    address_uz: "Toshkent viloyati, Chirchiq ko'chasi",
    address_ru: "Область Ташкент, улица Чирчик",
    address_en: "Tashkent Region, Chirchiq Street",
    email: "toshkentv@ecouz.uz",
    phoneNumber: "(95) 476-10-10",
    deputyImage: deputyImage4,
    navigateLink: "https://kengash.gov.uz/toshkent/deputy-detail/3127",
  },
  {
    id: 5,
    title_uz: "Farg'ona viloyat",
    title_ru: "Область Фергана",
    title_en: "Fergana Region",
    image: fargona,
    deputyName_uz: "Tojiyev Rustambek Rasulovich",
    deputyName_ru: "Тожиев Рустамбек Расулович",
    deputyName_en: "Rustambek Rasulovich Tojiyev",
    job_uz:
      "Farg'ona viloyat partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Фергана.",
    job_en:
      "Chairman of the Executive Committee of the Fergana Regional Party Organization.",
    address_uz:
      "Farg‘ona viloyati Farg‘ona shahar Tabassum mahalla fuqarolar yig‘ini Do‘stlik ko‘chasi 13-uyda joylashgan.",
    address_ru:
      "Область Фергана, город Фергана, махалла Табассум, улица Достлык, дом 13.",
    address_en:
      "Fergana Region, City of Fergana, Tabassum Mahalla, Dostlik Street, House 13.",
    nearAddress_uz: "Farg‘ona shahar Kirgili Neftchi o‘yingohi oldida.",
    nearAddress_ru:
      "Город Фергана, напротив спортивного комплекса Киргили Нефтчи.",
    nearAddress_en:
      "Fergana City, Opposite the Kirgili Neftchi Sports Complex.",
    deputyImage: deputyImage5,
    email: "fargona@ecouz.uz",
    phoneNumber: "(95) 650-40-40",
  },
  {
    id: 6,
    title_uz: "Surxondaryo viloyati",
    title_ru: "Область Сурхандарья",
    title_en: "Surkhandarya Region",
    image: surxandaryo,
    deputyName_uz: "Yoqubov Ibrohim Allanazarovich",
    deputyName_ru: "Ёқубов Иброҳим Алланазарович",
    deputyName_en: "Ibrohim Allanazarovich Yoqubov",
    job_uz:
      "Surxondaryo viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Сурхандарья.",
    job_en:
      "Chairman of the Executive Committee of the Surkhandarya Regional Party Organization.",
    email: "surxondaryo@ecouz.uz",
    phoneNumber: "(95) 307-75-75",
    address_uz:
      "Surxondaryo viloyati Termiz shahri Shodlik MFY Istiqlol ko‘chasi 64-uy",
    address_ru: "Область Сурхандарья, город Терmez, улица Истиклол, дом 64.",
    address_en:
      "Surkhandarya Region, City of Termez, Istiqlol Street, House 64.",
    nearAddress_uz:
      "Surxondaryo viloyati Termiz shahridagi Yoshlar markazi yonida.",
    nearAddress_ru: "Город Терmez, рядом с Центром Молодежи.",
    nearAddress_en: "Termez City, Next to the Youth Center.",
    deputyImage: deputyImage6,
  },
  {
    id: 7,
    title_uz: "Samarqand viloyati",
    title_ru: "Область Самарканд",
    title_en: "Samarqand Region",
    image: sirdaryo,
    deputyName_uz: "Abdullaev Baxtiyor Nosirovich",
    deputyName_ru: "Абдуллаев Бахтиёр Носирович",
    deputyName_en: "Baxtiyor Nosirovich Abdullaev",
    job_uz:
      "Samarqand viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Самарканд.",
    job_en:
      "Chairman of the Executive Committee of the Samarqand Regional Party Organization",
    email: "samarqand@ecouz.uz",
    phoneNumber: "(95) 478-30-30",
    address_uz:
      "Samarqand viloyati ekologiya, atrof-muhitni muhofaza qilish va iqlim o‘zgarishi boshqarmasi binosi. Gagarin ko‘chasi 27, Samarqand, O‘zbekiston",
    address_ru:
      "Область Самарканд, здание Управления экологии, охраны окружающей среды и изменения климата. Улица Гагарина 27, Самарканд, Узбекистан.",
    address_en:
      "Samarqand Region, Building of the Department of Ecology, Environmental Protection and Climate Change. Gagarin Street 27, Samarqand, Uzbekistan.",
    nearAddress_uz: "Samarqand viloyat 'Oltin Samarqand' restorani",
    nearAddress_ru: "Ресторан 'Олтин Самарканд'",
    nearAddress_en: "Oltin Samarkanq Restaurant",
    deputyImage: deputyImage7,
    navigateLink: "https://kengash.gov.uz/samarqand/deputy-detail/219",
  },
  {
    id: 8,
    title_uz: "Namangan viloyati",
    title_ru: "Область Наманган",
    title_en: "Namangan Region",
    image: namangan,
    deputyName_uz: "Nazarov Abdug‘affor Abdujabborovich",
    deputyName_ru: "Назаров Абдугаффор Абдужабборович",
    deputyName_en: "Abdug'affor Abdujabborovich Nazarov",
    job_uz: "Namangan viloyati partiya tashkiloti raisi.",
    job_ru: "Председатель партийной организации области Наманган.",
    job_en: "Chairman of the Namangan Regional Party Organization.",
    email: "namangan@ecouz.uz",
    phoneNumber: "(95) 611-50-50",
    address_uz: "Namangan viloyati Namangan Shahar G‘irvonsoy ko‘cha 242-uy",
    address_ru: "Область Наманган, город Наманган, улица Гирвонсой, дом 242.",
    address_en:
      "Namangan Region, City of Namangan, G'irvonsoy Street, House 242.",
    nearAddress_uz:
      "Namangan viloyati Namangan Shahrida joylashgan Afsonalar vodiysi",
    nearAddress_ru: "В Долине Легенд, город Наманган.",
    nearAddress_en: "In the Legends Valley, City of Namangan.",
    deputyImage: deputyImage8,
  },
  {
    id: 9,
    title_uz: "Navoiy viloyati",
    title_ru: "Область Навоий",
    title_en: "Navoi Region",
    image: navoiy,
    deputyName_uz: "Jalilov Alijon Saydullaevich",
    deputyName_ru: "Джалилов Алийджон Сайдуллаевич",
    deputyName_en: "Alijon Saydullaevich Jalilov",
    job_uz:
      "Navoiy viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Навои.",
    job_en:
      "Chairman of the Executive Committee of the Navoi Regional Party Organization.",
    email: "navoiy@ecouz.uz",
    phoneNumber: "(95) 308-85-85",
    address_uz:
      "Navoiy viloyati, Navoiy shahar Sadriddin Ayniy ko‘chasi, 34- uyda joylashgan.",
    address_ru: "Область Навои, город Навои, улица Садриддин Аиний, дом 34.",
    address_en:
      "Navoi Region, City of Navoi, Sadriddin Ayniy Street, House 34.",
    nearAddress_uz: "Navoiy shahar, NAVOIY FAYZ mehmonxonasi ro‘parasida",
    nearAddress_ru: "Рядом с гостиницей NAVOIY FAYZ.",
    nearAddress_en: "Opposite NAVOIY FAYZ Hotel",
    deputyImage: deputyImage9,
  },
  {
    id: 10,
    title_uz: "Jizzax viloyati",
    title_ru: "Область Джизак",
    title_en: "Jizzakh Region",
    image: jizzax,
    deputyName_uz: "Raxmonqulov Akram Xudayorovich",
    deputyName_ru: "Рахмонкулов Акрам Худойорович",
    deputyName_en: "Akram Khudayorovich Rakhmonkulov",
    job_uz:
      "Jizzax viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi.",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Джизак.",
    job_en:
      "Chairman of the Executive Committee of the Jizzakh Regional Party Organization.",
    email: "jizzax@ecouz.uz",
    phoneNumber: "(95) 323-25-25",
    address_uz:
      "Jizzax viloyati, Jizzax shahri Sh.Rashidov shox ko‘chasi 63-uyda joylashgan.",
    address_ru: "Область Джизак, город Джизак, улица Ш.Рашидов, дом 63.",
    address_en:
      "Jizzakh Region, City of Jizzakh, Sh. Rashidov Street, House 63.",
    nearAddress_uz: "Jizzax viloyat Diramma Teatri ro‘parasida.",
    nearAddress_ru: "Рядом с театром Дирама.",
    nearAddress_en: "Near Dirama Theater.",
    deputyImage: deputyImage10,
  },
  {
    id: 11,
    title_uz: "Buxoro viloyati",
    title_ru: "Область Бухара",
    title_en: "Bukhara Region",
    image: buxoro,
    deputyName_uz: "Siddiqova Sadoqat Gʻafforovna",
    deputyName_ru: "Сиддикова Садокат Ғаффорова",
    deputyName_en: "Sadoqat Gafforovna Siddikova",
    job_uz:
      "Buxoro viloyat partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi v.b",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Бухара.",
    job_en:
      "Acting Chairman of the Executive Committee of the Bukhara Regional Party Organization.",
    email: "buxoro@ecouz.uz",
    phoneNumber: "(95) 401-80-80",
    address_uz:
      "Buxoro viloyati Buxoro shahar Piridastgir mahalla fuqarolar yig‘ini, Piridastgir ko‘chasi 13-uyda joylashgan.",
    address_ru:
      "Область Бухара, город Бухара, махалла Пиридатгир, улица Пиридатгир, дом 13.",
    address_en:
      "Bukhara Region, City of Bukhara, Piridatgir Mahalla, Piridatgir Street, House 13.",
    nearAddress_uz: "Buxoro shahridagi “Ishga marhamat” monomarkazi binosida.",
    nearAddress_ru:
      "В здании монобрендового центра 'Ишга мархамат' в городе Бухара.",
    nearAddress_en: "In the ‘Ishga Marhamat’ Monobrand Center in Bukhara.",
    deputyImage: deputyImage11,
  },
  {
    id: 12,
    title_uz: "Andijon viloyati",
    title_ru: "Область Андиджан",
    title_en: "Andijan Region",
    image: andijon,
    deputyName_uz: "Akbarov Azizbek Abdusattarovich",
    deputyName_ru: "Акбаров Азизбек Абдусаттарович",
    deputyName_en: "Azizbek Abdusattarovich Akbarov",
    job_uz: "Andijon viloyati partiya tashkiloti raisi.",
    job_ru: "Председатель партийной организации области Андиджан.",
    job_en: "Chairman of the Andijan Regional Party Organization.",
    email: "andijon@ecouz.uz",
    phoneNumber: "(95) 605-60-60",
    address_uz:
      "Andijon viloyati, Andijon shahri YAyegi xayot MFY Navoiy shox ko‘chasi 87-uyda joylashgan.",
    address_ru: "Область Андиджан, город Андиджан, улица Навоий, дом 87.",
    address_en: "Andijan Region, City of Andijan, Navoiy Street, House 87.",
    nearAddress_uz:
      "Andijon shahar, viloyat Favqulotda vaziyatlar boshqanmasining o‘ng tomonida.",
    nearAddress_ru:
      "В  стороне правой от Управления по чрезвычайным ситуациям.",
    nearAddress_en: "On the right side of the Emergency Situations Department.",
    deputyImage: deputyImage12,
  },
  {
    id: 13,
    title_uz: "Qoraqalpog'iston Respublikasi",
    title_ru: "Республика Каракалпакстан",
    title_en: "Republic of Karakalpakstan",
    image: qoraqalpokiston,
    deputyName_uz: "Begimova Aysanem Polatovna",
    deputyName_ru: "Бегимова Айсанем Полатовна",
    deputyName_en: "Aysanem Polatovna Begimova",
    job_uz: "Qoraqalpog'iston Respublikasi partiya tashkiloti Kengashi raisi.",
    job_ru:
      "Председатель Совета партийной организации Республики Каракалпакстан.",
    job_en:
      "Chairman of the Council of the Karakalpakstan Republic Party Organization.",
    email: "qr@ecouz.uz",
    phoneNumber: "(95) 680-95-95",
    address_uz:
      "Qoraqalpog'iston Respublikasi, Nukus shahri A.Timur ko‘chasi 112 A -uyda joylashgan.",
    address_ru:
      "Республика Каракалпакстан, город Нукус, улица А.Тимур, дом 112 А.",
    address_en:
      "Republic of Karakalpakstan, City of Nukus, A. Timur Street, House 112 A.",
    nearAddress_uz: "Nukus shahar, Hamkor bank binosi o‘ng tomonida.",
    nearAddress_ru: "Город Нукус, справа от здания банка Хамкор.",
    nearAddress_en: "City of Nukus, to the right of the Hamkor Bank building.",
    deputyImage: deputyImage13,
  },
  {
    id: 14,
    title_uz: "Sirdaryo viloyati",
    title_ru: "Область Сырдарья",
    title_en: "Syrdarya Region",
    image: sirdaryo,
    deputyName_uz: "Tagayev Ikromjon Komiljonovich",
    deputyName_ru: "Тагаев Икромжон Комильжанович",
    deputyName_en: "Ikromjon Komiljonovich Tagayev",
    job_uz:
      "Sirdaryo viloyati partiya tashkiloti Kengashi Ijroiya qo‘mitasi raisi",
    job_ru:
      "Председатель Исполнительного комитета Совета партийной организации области Сырдарья.",
    job_en:
      "Chairman of the Executive Committee of the Syrdarya Regional Party Organization.",
    email: "sirdaryo@ecouz.uz",
    phoneNumber: "(95) 512-20-20",
    address_uz:
      "Sirdaryo viloyati, Guliston shahri, Islom Karimov ko‘chasi, 35-uy.",
    address_ru:
      "Область Сырдарья, город Гулистон, улица Ислам Каримов, дом 35.",
    address_en:
      "Syrdarya Region, City of Guliston, Islom Karimov Street, House 35.",
    nearAddress_uz: "Guliston shahar Hokimiyati binosi",
    nearAddress_ru: "Город Гулистон, здание хокимията.",
    nearAddress_en: "Guliston City, Hokimiyat Building.",
    deputyImage: "",
  },
];
