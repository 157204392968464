import { useGetEco } from "./eco-backend";

const usePageFetcher = (queryParam, query) => {
  const { isLoading, data, refetch, error } = useGetEco(
    queryParam,
    query && typeof query.page === "number" ? query : { is_all: true, ...query }
  );

  return {
    isLoading,
    data: data || [],
    refetch,
    error,
  };
};

export default usePageFetcher;
