import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadcrumbComponent from "../../shared/components/breadcrumb/BreadcrumbComponent";
import SectionTitle from "../../shared/components/sectionTitle";
import Loader from "../../shared/components/loader";
import NewsCard from "../news/components/NewsCard";

import usePageFetcher from "../../repository/usePageFetcher";
import { REGION_ENUM } from "../../shared/utils/constants/constant";
import { epochToTimeConverter } from "../../shared/utils/dateTimeUtils";

import "./style.css";

const RegionDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const regionName = location.pathname?.match(/\/regions\/([a-zA-Z0-9-]+)/)[1];

  const { isLoading, data, refetch } = usePageFetcher(`news`, {
    region: regionName,
  });

  useEffect(() => {
    refetch();
  }, [regionName]);

  return (
    <div className="region_details">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper region_details_container"
      >
        <BreadcrumbComponent
          parentPageTitle={"Asosiy"}
          parentPagePath={"/"}
          currentPageTitle={"Hududiy bo'limlar"}
        />
        <div style={{ marginTop: "40px" }}></div>
        <SectionTitle title={REGION_ENUM[regionName]} />
        <div style={{ marginTop: "40px" }}></div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.length >= 1 ? (
              <div className="news_box">
                {data?.map((news) => {
                  return (
                    <NewsCard
                      key={news.id}
                      image={news.img}
                      description={news[`title_${i18n.language}`]}
                      views={news.see}
                      postedDate={epochToTimeConverter(news?.posted_at)}
                      descriptionClassName={"all_news_description"}
                      imageClassName={"all_news_image"}
                      onClick={() => navigate(`/news/${news?.id}`)}
                    />
                  );
                })}
              </div>
            ) : (
              <p>{t("Ma'lumot to'pilmadi")} </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RegionDetails;
