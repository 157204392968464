import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import SectionTitle from "../../shared/components/sectionTitle";
import GaleryCard from "./components/GaleryCard";
import GaleryImage from "./components/GaleryImage";

import "./style.css";

import galeryImage from "../../shared/assets/galeryImage.png";

const gallerySlider = {
  spaceBetween: 20,
  breakpoints: {
    855: {
      slidesPerView: 1,
    },
    855: {
      slidesPerView: 3,
    },
  },
};

const Galery = () => {
  const [onChangeBody, setOnChangeBody] = useState();
  const navigate = useNavigate();

  return (
    <div className="galery_wrapper">
      <div className="content_wrapper galery_container">
        <SectionTitle
          color={onChangeBody ? "var(--gray-color" : "#0A5F22"}
          secondColor={onChangeBody ? "#0A5F22" : "var(--gray-color"}
          title={"VIDEOGALEREYA"}
          secondTitle={"FOTOGALEREYA"}
          onClick={() =>
            onChangeBody ? setOnChangeBody(false) : navigate("/video-gallery")
          }
          onSecondClick={() =>
            onChangeBody ? navigate("/photo-gallery") : setOnChangeBody(true)
          }
        />
        {onChangeBody ? (
          <GaleryImage />
        ) : (
          <div className="galery_card_wrapper">
            <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
              }}
              loop={true}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              {...gallerySlider}
              modules={[Navigation]}
              spaceBetween={50}
              className="gallery-vertical-swiper-container"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                <SwiperSlide key={index}>
                  <GaleryCard
                    galeryTitle={
                      "«MEHR KARVONI» HECh KIM MEHR VA E'TIBORDAN CHETDA QOLMAYDI!"
                    }
                    galeryVideo={galeryImage}
                    index={index}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Galery;
