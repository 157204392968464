export const REGION_ENUM = {
  andijon: "Andijon viloyati",
  xorazm: "Xorazm viloyati",
  buxoro: "Buxoro viloyati",
  surxondaryo: "Surxondaryo viloyati",
  qashqadaryo: "Qashqadaryo viloyati",
  navoiy: "Navoiy viloyati",
  samarqand: "Samarqand viloyati",
  jizzax: "Jizzax viloyati",
  sirdaryo: "Sirdaryo viloyati",
  "toshkent-v": "Toshkent viloyati",
  toshkent: "Toshkent shahri",
  fargona: "Fargʻona viloyati",
  namangan: "Namangan viloyati",
  qoraqalpoq: "Qoraqalpog'iston Respublikasi",
};

export const REVERSE_REGION_ENUM = {
  "Andijon viloyati": "andijon",
  "Xorazm viloyati": "xorazm",
  "Buxoro viloyati": "buxoro",
  "Surxondaryo viloyati": "surxondaryo",
  "Qashqadaryo viloyati": "qashqadaryo",
  "Navoiy viloyati": "navoiy",
  "Samarqand viloyati": "samarqand",
  "Jizzax viloyati": "jizzax",
  "Sirdaryo viloyati": "sirdaryo",
  "Toshkent viloyati": "toshkent-v",
  "Toshkent shahri": "toshkent",
  "Fargʻona viloyati": "fargona",
  "Namangan viloyati": "namangan",
  "Qoraqalpog'iston Respublikasi": "qoraqalpoq",
};
