import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import { languageMenu } from "../../configs/i18nConfig";

import "./style.css";

const Topbar = () => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(languageMenu[1]);
  const [openPopover, setOpenPopover] = useState(false);
  const [isGrayscale, setIsGrayscale] = useState(false);

  const handleEyeClick = () => {
    setIsGrayscale((prevState) => !prevState);
  };

  useEffect(() => {
    if (isGrayscale) {
      document.body.classList.add("grayscale");
    } else {
      document.body.classList.remove("grayscale");
    }
  }, [isGrayscale]);

  useEffect(() => {
    const detectedLanguageMenu = languageMenu.find((l) =>
      i18n.language.includes(l.code)
    );

    if (detectedLanguageMenu) setLanguage(detectedLanguageMenu);
  }, [i18n.language, languageMenu]);

  const handleOnLanguageMenuItemSelected = (item) => {
    if (language.id !== item.id) {
      i18n.changeLanguage(item.code);
      setLanguage(item);
      setOpenPopover(false);
    }
  };

  const content = languageMenu.map((option) => {
    return (
      <div
        className={`${
          option.id == language.id && "active_language_select"
        } language_select`}
        key={option.id}
        onClick={() => handleOnLanguageMenuItemSelected(option)}
      >
        <img src={option.icon} alt={option.title} />
        <span>{option.title}</span>
      </div>
    );
  });

  return (
    <div className="top_bar_wrapper">
      <div className="content_wrapper top_bar_container">
        <div className="one_line_container top_bar_left_side">
          <a
            href="http://old.ecouz.uz/"
            target="_blank"
            rel="noreferrer"
            className="one_line_container"
            style={{ color: "var(--white-color)" }}
          >
            <Icon icon="mdi:internet" style={{ marginRight: "5px" }} />{" "}
            {t("siteOldVersion")}
          </a>
          <div className="one_line_container">
            <Icon icon="ic:baseline-phone" width={"20px"} />
            <a href="tel:+998955115000"> +998 (95) 511 50 00</a>
          </div>
          <div className="one_line_container">
            <Icon icon="ph:warning" width={"20px"} />
            <a>{t("Sayt sinov rejimida")}</a>
          </div>
        </div>
        <div className="tob_bar_left">
          <div className="navbar_social_media_wrapper">
            <Icon
              icon="material-symbols:search"
              style={{ marginRight: "5px" }}
              width={"20px"}
            />
            <div className="navbar_height_divider"></div>
            <Icon
              icon="wpf:speaker"
              style={{ marginRight: "5px" }}
              width={"20px"}
            />
            <div className="navbar_height_divider"></div>
            <Icon
              icon={isGrayscale ? "mdi:eye-off" : "mdi:eye"}
              style={{ marginRight: "5px", cursor: "pointer" }}
              width={"20px"}
              onClick={handleEyeClick}
            />
            <div className="navbar_height_divider"></div>
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <Popover
                onOpenChange={() => setOpenPopover(!openPopover)}
                open={openPopover}
                content={content}
                trigger="click"
              >
                <div className="language_select_wrapper">
                  <p>{language.shortTitle}</p>
                  <img src={language.icon} alt={language.title} />
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
