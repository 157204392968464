import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ElectionByYear from "../../electionByYear";

import usePageFetcher from "../../repository/usePageFetcher";

export const useFetchElectionData = () => {
  const { isLoading, data } = usePageFetcher("election/");
  const [menuItems, setMenuItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && data) {
      const fetchedItems = data?.map((election) => ({
        key: `/election-${election.year}`,
        label: React.createElement(
          "p",
          { className: "navbat_dropdown_item" },
          `${t("Saylov")}`
        ),
        element: React.createElement(ElectionByYear, {
          title: `${t("Saylov")}`,
        }),
      }));

      const newMenuItems = fetchedItems?.slice(0, 1);

      setMenuItems(newMenuItems);
    }
  }, [isLoading, data]);

  return menuItems;
};
