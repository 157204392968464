import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";
import NewsCard from "../home/news/components/NewsCard";

import { mostCommentedNewsData } from "./utils/mostCommentedNewsData";

import "./style.css";

const MostCommentedNews = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <div className="all_type_news_wrapper">
      <div className="content_wrapper news_container">
        <SectionTitle title={"ENG KO’P MUHOKAMA BO’LGAN EKO-XABARLAR"} />

        <div className="news_box">
          {mostCommentedNewsData?.map((news) => {
            return (
              <NewsCard
                key={news.id}
                image={news.image}
                description={news[`title_${i18n.language}`]}
                views={news.views}
                postedDate={news.postedDate}
                descriptionClassName={"all_news_description"}
                imageClassName={"all_news_image"}
                onClick={() => navigate(`/most-commented/${news?.id}`)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MostCommentedNews;
