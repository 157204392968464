import image1 from "./../assets/1.png";
import image2 from "./../assets/2.png";
import image3 from "./../assets/3.png";
import image4 from "./../assets/4.png";
import image5 from "./../assets/5.png";
import image6 from "./../assets/6.png";

export const projectsData = [
  { id: 1, title: `"OROLGA MADAD" LOYIHASI`, image: image1 },
  { id: 2, title: `"OROLGA MADAD" LOYIHASI`, image: image2 },
  { id: 3, title: `"OROLGA MADAD" LOYIHASI`, image: image3 },
  { id: 4, title: `"YASHIL MAKON" LOYIHASI`, image: image4 },
  { id: 5, title: `"YASHIL MAKON" LOYIHASI`, image: image5 },
  { id: 6, title: `"YASHIL MAKON" LOYIHASI`, image: image6 },
];
