import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";

import usePageFetcher from "../repository/usePageFetcher";

import logo from "./../shared/assets/main-logo.jpeg";

import "./style.css";
import Loader from "../shared/components/loader";

const PartyInformation = () => {
  const { isLoading, data } = usePageFetcher("about/");
  const { i18n } = useTranslation();

  const [history, setHistory] = useState(null);

  useEffect(() => {
    setHistory(data?.[0]);
  }, [data]);

  return (
    <div className="partiya_wrapper">
      <div className="content_wrapper party_container">
        <SectionTitle title={"Partiya tarixi"} />
        <div className="party_information">
          <div className="header">
            <img
              data-aos="fade-right"
              src={logo}
              alt="Party Logo"
              className="logo"
            />
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: history?.[`party_history_${i18n.language}`],
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartyInformation;
