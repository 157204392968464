import React from "react";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";
import ReceptionCard from "./components/receptionCard";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const Reception = () => {
  const { i18n } = useTranslation();
  const { isLoading, data } = usePageFetcher("qabul/");

  return (
    <div className="reception_wrapper">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper reception_container"
      >
        <SectionTitle title="Fuqarolar qabuli" />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="reception_cards_wrapper">
            {data.map((deputy) => {
              return (
                <ReceptionCard
                  key={`1331${deputy.id}`}
                  image={deputy.img}
                  fullName={deputy[`name_${i18n.language}`]}
                  job={deputy[`job_${i18n.language}`]}
                  schedule={`${deputy?.hours_from} - ${deputy?.hours_to}`}
                  scheduleDay={deputy.admission_days}
                  phone={deputy.phone}
                  email={deputy.email}
                  telegramBot={deputy?.bot_link}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reception;
