import React from "react";
import { Icon } from "@iconify/react";

import { socialMediasIcons } from "../../shared/utils/socialMediasIcons";

import "./style.css";

const SocialMedias = () => {
  const renderSocialMediaLink = [
    "https://t.me/ecopartyuzb",
    "https://facebook.com/ecopartyuzb",
    "https://instagram.com/ecopartyuzb",
    "https://youTube.com/c/ecouz",
  ];

  return (
    <div className="social_medias_wrapper">
      <div className="content_wrapper social_media_container">
        {socialMediasIcons.map(({ id, icon }, index) => {
          return (
            <a
              href={renderSocialMediaLink[index]}
              rel="noreferrer"
              target="_blank"
              className="social_media"
              key={id}
            >
              <Icon className="social_media_icon" width={"28px"} icon={icon} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialMedias;
