import React from "react";

import SectionTitle from "../shared/components/sectionTitle";
import ECOCard from "../shared/components/card";

import { articlesData } from "./articlesData";

import "./style.css";

const Articles = () => {
  return (
    <div className="article_page_wrapper">
      <div className="content_wrapper article_page_container">
        <SectionTitle title={"Maqolalar"} />
        <div className="article_card_wrapper">
          {articlesData.map((article) => {
            return (
              <ECOCard
                title={article.title}
                author={article.author}
                description={article.description}
                link={article.link}
                date={article.date}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Articles;
