import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { latesNewsData } from "./utils/latesNewsData";

import "./style.css";

const LatesNews = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="lates_news_wrapper">
      <div className="content_wrapper lates_news_container">
        {latesNewsData.map((news, index) => {
          return (
            <div
              data-aos="fade-up"
              className="lates_news_card"
              onClick={() => navigate(news.navigate)}
              key={index + 283837}
            >
              <p className="lates_news_title">{t(news.title)}</p>
              <div className="lates_news_card_bottom">
                <div
                  style={{ background: "rgb(228, 228, 228)" }}
                  className="divider"
                ></div>
                <img
                  className="lates_news_img"
                  src={
                    typeof news.img === "object"
                      ? news.img?.[i18n.language]
                      : news.img
                  }
                  alt="latest_news"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatesNews;
