import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";

import Loader from "../../../shared/components/loader";

import usePageFetcher from "../../../repository/usePageFetcher";

const DeputiesCarousel = () => {
  const { isLoading, data } = usePageFetcher("deputy/");

  const imagesCollection = data.concat(
    data,
    data,
    data,
    data,
    data,
    data,
    data
  );

  return (
    <div className="deputies_wrapper">
      <div className="deputies_content" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 50,
              modifier: 5,
            }}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            navigation={true}
            spaceBetween={-30}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="deputies-carousel-container"
          >
            {imagesCollection.map((item, index) => {
              return (
                <SwiperSlide key={index + 6234643643}>
                  <a rel="noreferrer" href={item.link} target="_blank">
                    <img
                      src={item.img}
                      alt="deputyImage"
                      className="deputies_img"
                      effect="blur"
                    />
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default DeputiesCarousel;
