import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import SectionTitle from "../shared/components/sectionTitle";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const CentralLeadership = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, data } = usePageFetcher("central-council/");

  return (
    <section className="central_leadership">
      <div className="content_wrapper central_leadership_container">
        <SectionTitle title={t("Rahbariyat")} />

        {isLoading ? (
          <Loader />
        ) : (
          <div className="leadership_grid">
            {data?.map((data, index) => (
              <div className="leadership_card" key={index} data-aos="fade-up">
                <LazyLoadImage
                  src={data.img}
                  alt={data.name}
                  effect="blur"
                  className="leadership_photo"
                />
                <div className="leadership_info">
                  <h3 className="leadership_name">
                    {data[`title_${i18n.language}`]}
                  </h3>
                  <p className="leadership_job">
                    {data[`job_${i18n.language}`]}
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Faks")}:</strong>{" "}
                    <span>+998 (71) 208-36-05</span>
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Telefon raqam")}:</strong>{" "}
                    <span>{data.phone_number}</span>
                  </p>
                  <p className="leadership_contact">
                    <strong>{t("Email")}:</strong> <span>{data.email}</span>
                  </p>
                  <div
                    onClick={() => navigate(`/central-leadership/${data?.id}`)}
                    className="more_details_button"
                  >
                    {t("Batafsil")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default CentralLeadership;
