import { useEffect, useState } from "react";

const useInfiniteScroll = (fetchMore, isLoading, hasMore) => {
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (isLoading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore) {
          fetchMore();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (node) observer.observe(node);

    return () => {
      if (node) observer.unobserve(node);
    };
  }, [node, isLoading, hasMore]);

  return setNode;
};

export default useInfiniteScroll;
