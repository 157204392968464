export const navbarRoutes = [
  {
    id: 3,
    title: "Partiya a'zolari",
    route: "",
    subItems: [
      {
        id: 148,
        title: "Markaziy Kengash xodimlari",
        subRoute: "/central-council",
      },
    ],
  },
  {
    id: 4,
    title: "Sizning deputatingiz",
    route: "",

    subItems: [
      {
        id: 126,
        title: "Partiyaning Oliy Majlis Senati a'zolari",
        subRoute: "/senator",
      },
      // {
      //   id: 115,
      //   title:
      //     "Partiyaning Oliy Majlis Qonunchilik palatasidagi fraksiyasi a'zolari",
      //   subRoute: "",
      // },
    ],
  },
  {
    id: 3,
    title: "Yoshlar qanoti",
    route: "",
    subItems: [
      {
        id: 148,
        title: "EKO volontyorlar",
        subRoute: "/volunteers",
      },
      {
        id: 148,
        title: "Yoshlar parlamenti",
        subRoute: "/youth-parliament",
      },
    ],
  },
  {
    id: 5,
    title: "Hududiy bo'limlar",
    route: "",
    subItems: [
      {
        id: 13,
        title: "Qoraqalpog'iston Respublikasi",
        subRoute: "/regions/qoraqalpoq",
      },
      {
        id: 12,
        title: "Andijon viloyati",
        subRoute: `/regions/andijon`,
      },
      {
        id: 11,
        title: "Buxoro viloyati",
        subRoute: `/regions/buxoro`,
      },
      {
        id: 10,
        title: "Jizzax viloyati",
        subRoute: `/regions/jizzax`,
      },
      {
        id: 2,
        title: "Qashqadaryo viloyati",
        subRoute: `/regions/qashqadaryo`,
      },
      {
        id: 9,
        title: "Navoiy viloyati",
        subRoute: `/regions/navoiy`,
      },
      {
        id: 8,
        title: "Namangan viloyati",
        subRoute: `/regions/namangan`,
      },
      {
        id: 7,
        title: "Samarqand viloyati",
        subRoute: `/regions/samarqand`,
      },
      {
        id: 6,
        title: "Surxondaryo viloyati",
        subRoute: `/regions/surxondaryo`,
      },
      {
        id: 14,
        title: "Sirdaryo viloyati",
        subRoute: `/regions/sirdaryo`,
      },
      {
        id: 4,
        title: "Toshkent viloyati",
        subRoute: `/regions/toshkent-v`,
      },
      {
        id: 5,
        title: "Farg'ona viloyati",
        subRoute: `/regions/fargona`,
      },
      {
        id: 3,
        title: "Xorazm viloyati",
        subRoute: `/regions/xorazm`,
      },
      {
        id: 1,
        title: "Toshkent shahri",
        subRoute: `/regions/toshkent`,
      },
    ],
  },
];
