import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";
import NewsCard from "../home/news/components/NewsCard";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";
import { epochToTimeConverter } from "../shared/utils/dateTimeUtils";
import useInfiniteScroll from "../shared/hook/useInfiniteScroll";

import "./style.css";

const News = ({ navigatePath }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [page, setPage] = useState(1);
  const [newsData, setNewsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const ITEMS_PER_PAGE = 6;
  const { isLoading, data } = usePageFetcher("news/", {
    page,
    limit: ITEMS_PER_PAGE,
  });

  useEffect(() => {
    if (Array.isArray(data?.results)) {
      setNewsData((prevData) => [...prevData, ...data.results]);

      if (page >= data?.total_pages || data.results.length === 0) {
        setHasMore(false);
      }
    } else {
      console.error("Data is not an array:", data);
    }
  }, [data, page]);

  const fetchMore = () => {
    if (!isLoading && hasMore && page < data?.total_pages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const setObserverNode = useInfiniteScroll(fetchMore, isLoading, hasMore);

  return (
    <div className="all_type_news_wrapper">
      <div className="content_wrapper news_container">
        <SectionTitle title={"Yangiliklar"} />
        {isLoading && page === 1 ? (
          <Loader />
        ) : (
          <div className="news_box">
            {newsData.map((news) => (
              <NewsCard
                key={news.id}
                image={news.img}
                description={news[`title_${i18n.language}`]}
                views={news.see}
                postedDate={epochToTimeConverter(news?.posted_at)}
                descriptionClassName={"all_news_description"}
                imageClassName={"all_news_image"}
                onClick={() => navigate(`${navigatePath}/${news?.id}`)}
              />
            ))}
            <div
              ref={setObserverNode}
              style={{ height: "20px", backgroundColor: "transparent" }}
            >
              {isLoading && <Loader />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
