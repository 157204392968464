import moment from "moment";
import "moment/locale/uz";
import "moment/locale/ru";

moment.updateLocale("uz", {
  months: [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ],
  weekdays: [
    "Yakshanba",
    "Dushanba",
    "Seshanba",
    "Chorshanba",
    "Payshanba",
    "Juma",
    "Shanba",
  ],
});

export const formatLocalizedDateTime = (time) => {
  const locale = localStorage.getItem("i18nextLng");
  moment.locale(locale);
  const suffix = locale === "ru" ? "год" : locale === "uz" ? "yil" : "";
  const dateYearTimeFormat = `DD MMMM YYYY [${suffix}]`;
  const dateTimeFormat = "HH:mm, DD MMMM";
  return moment()
    .format(time ? dateTimeFormat : dateYearTimeFormat)
    .trim();
};

export const formatDateWithMoment = (isoString) => {
  return moment(isoString).format("DD.MM.YYYY");
};

export const epochToTimeConverter = (epoch) => {
  return moment.unix(epoch).format("DD.MM.YYYY");
};
