import axios from "axios";
import { useQuery } from "react-query";

const domain = "https://api.ecouz.uz";

export const getEco = async (queryLink, queryOptions) => {
  try {
    const response = await axios.get(`${domain}/${queryLink}`, {
      params: queryOptions,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateEco = async (
  method = "post",
  queryLink,
  queryOptions,
  domainLink = domain
) => {
  const config = {
    headers: {
      "Content-Type":
        queryOptions instanceof FormData
          ? "multipart/form-data"
          : "application/json",
    },
  };
  try {
    const response = await axios[method](
      `${domainLink}/${queryLink}`,
      queryOptions,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const useGetEco = (queryLink, queryOptions) => {
  return useQuery(
    [queryLink, queryOptions],
    () => getEco(queryLink, queryOptions),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};
