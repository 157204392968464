import React, { useEffect } from "react";
import Aos from "aos";
import { QueryClient, QueryClientProvider } from "react-query";

import Layout from "./shared/layout/Layout";
import Loader from "./shared/components/homePageLoader";

import { ElectionProvider } from "./shared/context/ElectionContext";

import "./shared/global.css";

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      mirror: true,
      once: false,
    });
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ElectionProvider>
        <Loader />
        <Layout />
      </ElectionProvider>
    </QueryClientProvider>
  );
};

export default App;
