import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import { socialMediasIcons } from "../../shared/utils/socialMediasIcons";
import { updateEco } from "../../repository/eco-backend";

import "./style.css";

import mainLogo from "./../../shared/assets/logo.svg";

const SmallFooter = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const renderSocialMediaLink = [
    "https://t.me/ecopartyuzb",
    "https://facebook.com/ecopartyuzb",
    "https://instagram.com/ecopartyuzb",
    "https://youTube.com/c/ecouz",
  ];

  const onClickSubmit = async (e) => {
    e.preventDefault();

    if (!email) return;

    const formData = new FormData();
    formData.append("email", email);

    try {
      await updateEco("post", "subscribed-email/", formData);
      setEmail("");
      alert(t("successfullySubscribed"));
    } catch (error) {
      alert("Xatolik", error);
    }
  };

  return (
    <div className="small_footer_wrapper">
      <div className="content_wrapper small_footer_container">
        <img
          data-aos="fade-down"
          className="small_footer_logo"
          src={mainLogo}
          alt="logo"
        />
        <div data-aos="fade-down" className="small_footer_social_medias">
          {socialMediasIcons.map(({ id, icon }, index) => (
            <a
              href={renderSocialMediaLink[index]}
              rel="noreferrer"
              target="_blank"
              key={id}
            >
              <Icon
                width="40px"
                className="small_footer_social_media"
                icon={icon}
              />
            </a>
          ))}
        </div>
        <form
          onSubmit={onClickSubmit}
          data-aos="fade-down"
          className="small_footer_subscribe"
        >
          <input
            className="small_footer_subscribe_input"
            placeholder={t("enterEmail")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="small_footer_subscribe_button">
            {t("subscribe")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SmallFooter;
