import React from "react";

import Main from "./main";
import AdditionalInfo from "./additionalInfo";
import News from "./news";
import ContactUs from "./contactUs";
import { Partnership } from "./partnership";
import Donation from "./donation";
import SaveTrees from "./saveTrees";
import LatesNews from "./latestNews";
import Deputies from "./deputies";
import SmallFooter from "./smallFooter";
import Galery from "./galery";
import HelpUs from "./helpUs";
import TerritorialDivisions from "./territorialDivisions";
import Articles from "./articles";

const Home = () => {
  return (
    <>
      <Main />
      <News />
      <AdditionalInfo />
      <SaveTrees />
      <LatesNews />
      <Deputies />
      <TerritorialDivisions />
      <Articles />
      <SmallFooter />
      <Galery />
      <HelpUs />
      <Donation />
      <ContactUs />
      <Partnership />
    </>
  );
};

export default Home;
